import React, { FC, useMemo } from 'react';

import { SORT_ORDER } from '@evinced-private/ui-common';

import { IssueSeverities } from '../../interfaces/IssuesOverviewResponse';
import SeverityService from '../../services/severities/SeverityService';
import ChartWrapper from '../data-displays/overview-charts/chart-wrapper/ChartWrapper';

import EvincedAdvantageBarV2 from './bars/EvincedAdvantageBarV2';

interface EvincedAdvantageProps {
	totalIssues: number;
	severitiesData: IssueSeverities[];
}

const EvincedAdvantageChartV3: FC<EvincedAdvantageProps> = ({ totalIssues, severitiesData }) => {
	const orderedSeveritiesData = useMemo(() => {
		return severitiesData?.sort((a, b) =>
			SeverityService.sortBySeverity(a.severity, b.severity, SORT_ORDER.DESC)
		);
	}, [severitiesData]);

	if (totalIssues === 0) {
		return null;
	}

	return (
		<ChartWrapper
			title="Evinced advantage: auto detection of critical issues chart"
			className="avoid-break"
		>
			<EvincedAdvantageBarV2
				severitiesData={orderedSeveritiesData}
				totalCriticalIssues={totalIssues}
			/>
		</ChartWrapper>
	);
};

export { EvincedAdvantageChartV3 };
