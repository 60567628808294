import { FC, useEffect } from 'react';

import EnvironmentHelper from '../../helpers/EnvironmentHelper';
import { useConfiguration } from '../../providers/configurationProvider/ConfigurationProvider';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import {
	initUsageAnalyticsService,
	isUsageAnalyticsServiceInitialized,
	sendUserActivityEvent
} from '../../services/analytics/UsageAnalyticsService';
import { SiteScannerUiToggles } from '../../services/LocalTogglesService';

export const UsageAnalyticsInitializer: FC = () => {
	const { getToggle } = useConfiguration();
	const { user, tenant } = useUserTenant();

	useEffect(() => {
		if (!user?.id || tenant?.id) {
			return;
		}
		if (isUsageAnalyticsServiceInitialized()) {
			return;
		}

		if (
			getToggle(SiteScannerUiToggles.ENABLE_EVINCED_USAGE_ANALYTICS) !== false ||
			EnvironmentHelper.isDev()
		) {
			// init
			initUsageAnalyticsService(user?.id, tenant?.id);

			// send user activity event
			sendUserActivityEvent();
		}
	}, [user?.id, tenant?.id, getToggle]);
	return null;
};
