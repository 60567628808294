/* eslint-disable react/no-unstable-nested-components */
import React, { FC } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import * as H from 'history';

import PrivateRoute from './components/auth/PrivateRoute';
import AuthenticationChecker from './components/authentication-checker/AuthenticationChecker';
import SiteScannerHeader from './components/header/SiteScannerHeader';
import NotFound404Page from './components/pages/not-found-404-page/NotFound404Page';
import { UsageAnalyticsInitializer } from './components/usage-analytics/UsageAnalyticsInitializer';
import { MAIN_TITLE } from './consts/Title';
import DeviceHelper from './helpers/DeviceHelper';
import RoutesHelper from './helpers/RoutesHelper';
import AuthToken from './pages/authentication/AuthToken';
import LoginPage from './pages/authentication/LoginPage';
import SignupPage from './pages/authentication/SignupPage';
import AzureAuthenticationPage from './pages/azure-authentication-page/AzureAuthenticationPage';
import HomePageRouter from './pages/dashboard/HomePageRouter';
import InvitationActivationPage from './pages/invitation-activation/InvitationActivationPage';
import InvitationRedirectionPage from './pages/invitation-redirection/InvitationRedirectionPage';
import JiraAuthenticationPage from './pages/jira-authentication-page/JiraAuthenticationPage';
import NoTenantPage from './pages/no-tenant/NoTenantPage';
import NotSupportedScreenSizePage from './pages/not-supported-screen-size/NotSupportedScreenSizePage';
import ProductActivationPage from './pages/product-activation/ProductActivationPage';
import PropertyRouter from './pages/property/PropertyRouter';
import ScreenshotWithBoundingBoxesPage from './pages/screenshot-with-bounding-boxes/ScreenshotWithBoundingBoxesPage';
import Unauthorized403Page from './pages/unauthorized-403-page/Unauthorized403Page';
import { AppProvider } from './providers/AppProvider';
import { CollectionsProvider } from './providers/collections/CollectionsProvider';
import { DashboardProvider } from './providers/dashboard/DashboardProvider';
import { NavigationProvider } from './providers/navigationProvider/NavigationProvider';
import PropertyViewProvider from './providers/PropertyViewProvider';

import './App.scss';

type IApp = {
	history: H.History;
};

const App: FC<IApp> = ({ history }) => {
	if (!DeviceHelper.isDeviceSupported()) {
		return <NotSupportedScreenSizePage />;
	}

	const isAuthenticationRequired = (pathname: string): boolean => {
		const unauthenticatedRoutes = [
			'/signup',
			'/connect',
			'/login',
			'/jira-authentication',
			'/azure-authentication',
			'/screenshots'
		];
		const isUnauthenticatedPage = unauthenticatedRoutes.some((path) => {
			return pathname.startsWith(path);
		});
		return !isUnauthenticatedPage;
	};

	return (
		<AppProvider>
			<UsageAnalyticsInitializer />
			<Router history={history}>
				<div className="app">
					<NavigationProvider>
						<Route
							render={({ location }) => {
								return isAuthenticationRequired(location.pathname) ? (
									<AuthenticationChecker />
								) : null;
							}}
						/>

						{/* Need to use a Route for the Header to have access
						to the path variables, otherwise useParams don't work.
						Note that the order of the items in the path list matters */}
						<Route
							path={[
								'/properties/:propertyId/scans/:scanId',
								'/properties/:propertyId',
								'/web-agent/properties/:propertyId',
								'/'
							]}
							render={({ location }) => {
								// don't show the header on unauthenticated screens
								const isAuthRequired = isAuthenticationRequired(location.pathname);
								document.title = 'Evinced Site Scanner';

								if (isAuthRequired) {
									return <SiteScannerHeader isAuthenticatedPage={true} />;
								}
								return null;
							}}
						/>
						<Route exact path="/connect" component={InvitationRedirectionPage} />
						<main className="main-section" aria-labelledby={MAIN_TITLE}>
							<Switch>
								<Route path="/screenshots" component={ScreenshotWithBoundingBoxesPage} />
								<Route exact path="/login" render={() => <LoginPage />} />
								<Route exact path="/signup" render={() => <SignupPage />} />
								<Route exact path="/login-success" render={() => <AuthToken />} />
								<Route exact path="/jira-authentication" component={JiraAuthenticationPage} />
								<Route exact path="/azure-authentication" component={AzureAuthenticationPage} />
								<PrivateRoute
									path="/properties/new"
									component={() => {
										return <PropertyRouter />;
									}}
								/>
								<PrivateRoute
									path="/properties/:propertyId"
									component={() => {
										return (
											<PropertyViewProvider>
												<PropertyRouter />
											</PropertyViewProvider>
										);
									}}
								/>
								<PrivateRoute
									exact
									path="/invitation-activation"
									component={InvitationActivationPage}
								/>
								<PrivateRoute exact path="/product-activation" component={ProductActivationPage} />
								<PrivateRoute
									exact
									path={RoutesHelper.getNoTenantPage()}
									component={NoTenantPage}
								/>
								<PrivateRoute
									exact
									path={RoutesHelper.getUnauthorized403Page()}
									component={Unauthorized403Page}
								/>
								<PrivateRoute
									path={RoutesHelper.getHomepagePath()}
									component={() => {
										return (
											<CollectionsProvider>
												<DashboardProvider>
													<HomePageRouter />
												</DashboardProvider>
											</CollectionsProvider>
										);
									}}
								/>
								<Route render={() => <NotFound404Page />} />
							</Switch>
						</main>
					</NavigationProvider>
				</div>
			</Router>
		</AppProvider>
	);
};

export default App;
