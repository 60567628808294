import React, { FC } from 'react';

import { BUTTON_TYPES, EvButton, EvSelect, OptionType } from '@evinced-private/ui-common';

interface IPerformanceOverTimeSelectGroupV2 {
	selectedTimeFrame: OptionType[];
	onTimeFrameSelect: (value: OptionType) => void;
	TIME_FRAME_OPTIONS: OptionType[];
	selectedViews: OptionType[];
	onMultiViewSelect: (values: OptionType[]) => void;
	viewsOptions: OptionType[];
	isApplyButtonDisabled: boolean;
	onApplyView: () => void;
}

export const PerformanceOverTimeSelectGroupV2: FC<IPerformanceOverTimeSelectGroupV2> = ({
	selectedTimeFrame,
	onTimeFrameSelect,
	TIME_FRAME_OPTIONS,
	selectedViews,
	onMultiViewSelect,
	viewsOptions,
	isApplyButtonDisabled,
	onApplyView
}) => {
	return (
		<div className="select-group-v2">
			<label htmlFor="performance-over-time-select-time-frame">Time frame</label>
			<EvSelect
				value={selectedTimeFrame}
				onChange={onTimeFrameSelect}
				options={TIME_FRAME_OPTIONS}
				placeholder="Select time frame"
				id="performance-over-time-select-time-frame"
			/>
			<label htmlFor="performance-over-time-select-views">Views</label>
			<EvSelect
				value={selectedViews}
				onChange={onMultiViewSelect}
				options={viewsOptions}
				placeholder="Select views"
				isMulti
				id="performance-over-time-select-views"
			/>
			<EvButton disabled={isApplyButtonDisabled} type={BUTTON_TYPES.PRIMARY} onClick={onApplyView}>
				Apply
			</EvButton>
		</div>
	);
};
