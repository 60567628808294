import React, { FC, useEffect, useState } from 'react';

import { EvLink, EvLinkTypes, EvTableColumn, SORT_ORDER } from '@evinced-private/ui-common';

import UrlFormattingHelper from '../../helpers/UrlFormattingHelper';
import { useTablesStore } from '../../providers/tablesProvider/TablesProvider';
import SiteScannerTable from '../common/site-scanner-table/SiteScannerTable';
import SearchFilter from '../search-filter/SearchFilter';

import './PropertyUrlsTable.scss';

interface IPropertyUrlsTableProps {
	urlSetUrls: string[];
	urlSetId: string;
}

const PropertyUrlsTable: FC<IPropertyUrlsTableProps> = ({
	urlSetUrls,
	urlSetId
}: IPropertyUrlsTableProps) => {
	const [filteredUrls, setFilteredUrls] = useState(urlSetUrls);
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const tablesStore = useTablesStore();

	const tableId = `urlset-${urlSetId}-urls-table`;

	useEffect(() => {
		const { sizePerPage } = tablesStore.getPagination(tableId);
		const filteredUrls = urlSetUrls.filter((url) => {
			return url.toLowerCase().includes(searchFilterValue.toLowerCase());
		});
		tablesStore.updatePagination(tableId, { page: 1, sizePerPage });
		setFilteredUrls(filteredUrls);
	}, [searchFilterValue, urlSetUrls, tablesStore, tableId]);

	const getColumns = (): EvTableColumn[] => {
		return [
			{
				dataField: 'url',
				text: 'URL',
				sort: true,
				// eslint-disable-next-line react/no-unstable-nested-components
				formatter: (url: string) => {
					const formattedUrl = UrlFormattingHelper.removeAllTrailingSlashes(url);
					return (
						<EvLink
							style={{ maxWidth: 'unset', display: 'inline-grid' }}
							url={url}
							linkText={formattedUrl}
							customTooltip={{ dataTip: url, dataFor: 'table-tooltip' }}
							type={EvLinkTypes.DARK}
						/>
					);
				}
			}
		];
	};
	const tableData = filteredUrls.map((url) => ({ url, id: url }) || []);
	return (
		<div className="property-urls-table">
			<SearchFilter searchInputValue={searchFilterValue} onInputChange={setSearchFilterValue} />
			<SiteScannerTable
				tableId={tableId}
				tableData={tableData}
				totalCount={tableData.length}
				columns={getColumns()}
				options={{
					title: 'PropertyUrls',
					pagination: true,
					caption: 'URLs',
					dataType: 'pages',
					defaultSorted: [
						{
							dataField: 'url',
							order: SORT_ORDER.ASC
						}
					]
				}}
			/>
		</div>
	);
};

export default PropertyUrlsTable;
