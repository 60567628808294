import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import { EvEllipsedTextWithTooltip, EvMainTitle } from '@evinced-private/ui-common';

import RoutesHelper from '../../helpers/RoutesHelper';
import UrlDrillDownViewV2 from '../../pages/scan-analysis/views/url-drilldown/UrlDrillDownViewV2';
import { useNavigation } from '../../providers/navigationProvider/NavigationProvider';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import EvPropertyViews from '../common/ev-property-views/EvPropertyViews';
import PropertyViewLoadErrorPage from '../common/ev-property-views/PropertyViewLoadErrorPage';

import './DrilldownPage.scss';

const UrlDrilldownPage: FC = () => {
	const { propertyId, scanId } = useParams<{ [key: string]: string }>();
	const { setCurrentRoute } = useNavigation();
	const history = useHistory();
	const getQueryParams = (): URLSearchParams => {
		return new URLSearchParams(history.location.search);
	};
	const url: string = getQueryParams().get('url');

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const viewId: string = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);
	const viewLoadErr: boolean = useMemo(() => properyViewContext?.loadViewErr, [properyViewContext]);

	const redirectionTarget: string = RoutesHelper.getScanResultPagesBreakdownPath(
		propertyId,
		scanId
	);

	const title: JSX.Element = useMemo(
		() => (
			<>
				<EvEllipsedTextWithTooltip text={url} maxWidth={600} />
				&nbsp;details
			</>
		),
		[url]
	);

	useEffect(() => {
		if (propertyId && url) {
			setCurrentRoute({
				id: `${url}-${propertyId}`,
				url: redirectionTarget,
				title: url
			});
		}
		return () => {
			setCurrentRoute(null);
		};
	}, [propertyId, setCurrentRoute, url, redirectionTarget]);

	if (viewLoadErr) {
		return <PropertyViewLoadErrorPage propertyId={propertyId} />;
	}

	return (
		<div className="drilldown-page">
			<div className="drilldown-page-content">
				<div className="header">
					<EvMainTitle className="page-heading with-breadcrumbs">{title}</EvMainTitle>
					<div className="filtered-views-controller">
						<EvPropertyViews propertyId={propertyId} />
					</div>
				</div>
				<UrlDrillDownViewV2 viewId={viewId} scanId={scanId} url={url} propertyId={propertyId} />
			</div>
		</div>
	);
};

export default UrlDrilldownPage;
