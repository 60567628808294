import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	BUTTON_TYPES,
	EvButton,
	EvIssueDetails,
	IIssueElement,
	IIssueTag,
	IssueDetailsHelper,
	ScreenshotDetails
} from '@evinced-private/ui-common';

import RoutesHelper from '../../helpers/RoutesHelper';
import UrlNormalizingHelper from '../../helpers/UrlNormalizingHelper';
import logger from '../../services/Logger';
import scanService from '../../services/scan/ScansService';
import { ScreenshotService } from '../../services/ScreenshotService';
import { Report } from '../../types/ReportModel';
import CopyToClipboard from '../common/copy-to-clipboard/CopyToClipboard';
import TicketsSystems from '../tickets-systems/TicketsSystems';

interface IIssueDetailsProps {
	issueId: string;
	scanId?: string;
	setError: (e: Error) => void;
	setTitle?: (title: string) => void;
	isPage?: boolean;
}

const IssueDetailsBlock: FC<IIssueDetailsProps> = ({
	issueId,
	scanId,
	setError,
	setTitle,
	isPage
}: IIssueDetailsProps) => {
	const [issue, setIssue] = useState<Report>(null);
	const [isLoading, setLoading] = useState(true);
	const { propertyId } = useParams<{ propertyId: string }>();

	const openIssueInNewTab = useCallback((): void => {
		window.open(RoutesHelper.getIssueDetailsPath(propertyId, scanId, issueId), '_blank');
	}, [propertyId, scanId, issueId]);

	const renderCopyToClipboardButton = useCallback(
		(textToCopy: string) => (
			<CopyToClipboard renderJustIcon textToCopy={textToCopy} iconTitle="Copy Selector" />
		),
		[]
	);

	const fetchIssue = useCallback(async (): Promise<void> => {
		try {
			setLoading(true);
			const issueData: Report = await scanService.getScanIssue(scanId, issueId);
			setIssue(issueData);
			if (setTitle) {
				setTitle(issueData?.type?.name);
			}
		} catch (e) {
			setError(e);
			logger.warn(e);
		}
		setLoading(false);
	}, [scanId, issueId, setError, setTitle]);

	useEffect(() => {
		if (!issue) {
			fetchIssue();
		}
	}, [issue, fetchIssue]);

	const {
		description,
		type,
		severity,
		pageScreenshot,
		elements,
		summary,
		note,
		knowledgeBaseLink,
		tags
	} = issue || {};
	const { pageUrl } = elements?.[0] ?? {};
	const urlToCopy = `${UrlNormalizingHelper.getBaseURL()}${RoutesHelper.getIssueDetailsPath(
		propertyId,
		scanId,
		issueId
	)}`;

	const topRowActions = (
		<div className="action-buttons">
			<CopyToClipboard
				textToCopy={urlToCopy}
				buttonType={BUTTON_TYPES.SECONDARY}
				dataAutomationId="copy-issue-link-btn"
			/>
			{!isPage && (
				<EvButton
					type={BUTTON_TYPES.SECONDARY}
					title="Open in a New Tab"
					onClick={openIssueInNewTab}
				>
					Open in a New Tab
				</EvButton>
			)}
			<TicketsSystems issue={issue} />
		</div>
	);

	const screenshotDetails: ScreenshotDetails = {
		pageScreenshotUrl: pageScreenshot,
		boundingBoxes: elements?.map((element) => element.boundingBox) || []
	};

	return (
		<EvIssueDetails
			issue={{
				type: type?.name,
				severity: severity?.name,
				effect: IssueDetailsHelper.getEffectString(description || ''),
				cause: IssueDetailsHelper.getCauseString(description || ''),
				howToFix: IssueDetailsHelper.getHowToString(description || ''),
				summary,
				note,
				knowledgeBaseLink,
				pageUrl,
				screenshotDetails,
				level: tags?.[0]?.description,
				pageTitle: '',
				elements: elements as IIssueElement[],
				tags: tags as IIssueTag[]
			}}
			isLoading={isLoading}
			isFullPageMode={isPage}
			topRowActions={topRowActions}
			screenshotService={ScreenshotService}
			logger={logger}
			copyToClipboardButton={renderCopyToClipboardButton}
		/>
	);
};

export default IssueDetailsBlock;
