import React, { FC, useRef } from 'react';

import {
	BUTTON_TYPES,
	DropdownPosition,
	EvDropdownMenu,
	EvIcon,
	IDropdownMenuOption
} from '@evinced-private/ui-common';

import CaretDownBlueIcon from 'src/components/icons/CaretDownBlueIcon.svg';

import './ExportDropdownMenu.scss';

interface IExportDropdownMenuProps {
	dropdownId: string;
	dropdownOptions: IDropdownMenuOption[];
	className?: string;
}

const ExportDropdownMenu: FC<IExportDropdownMenuProps> = ({
	dropdownId,
	dropdownOptions,
	className
}) => {
	const downloadBtnRef = useRef(null);

	return (
		<EvDropdownMenu
			className={`export-dropdown-menu ${className}`}
			id={dropdownId}
			options={dropdownOptions}
			position={DropdownPosition.BOTTOM_LEFT}
			triggerButtonProps={{
				ref: downloadBtnRef,
				type: BUTTON_TYPES.ACTION,
				ariaHaspopup: 'menu',
				className: 'export-button',
				dataAutomationId: 'export-dropdown-button',
				children: (
					<>
						Export
						<div className="menu-trigger-icon">
							<EvIcon icon={CaretDownBlueIcon} small />
						</div>
					</>
				)
			}}
		/>
	);
};

export default ExportDropdownMenu;
