import { SeriesLineOptions } from '@evinced-private/ui-common';

import { millisToDays } from '../DateFormatHelper';

export const calculateXAxisTickPositions = (multiChartData: SeriesLineOptions[]): number[] => {
	/*
		earliestDateTimeInMillis - data point furthest away from now
		latestDateTimeInMillis - data point closest to now
	*/
	const { earliestDateTimeInMillis, latestDateTimeInMillis } = multiChartData.reduce(
		(result, series) => {
			series.data.forEach((dataPoint) => {
				if (!(dataPoint instanceof Object) || Array.isArray(dataPoint)) {
					return;
				}
				if (dataPoint.x < result.earliestDateTimeInMillis) {
					result.earliestDateTimeInMillis = dataPoint.x;
				}
				if (dataPoint.x > result.latestDateTimeInMillis) {
					result.latestDateTimeInMillis = dataPoint.x;
				}
			});
			return result;
		},
		{ earliestDateTimeInMillis: Infinity, latestDateTimeInMillis: -Infinity }
	);

	const range = latestDateTimeInMillis - earliestDateTimeInMillis;

	if (range === 0) {
		return [];
	}

	const rangeInDays = millisToDays(range);

	/*
		Value by which the ticks of the x axis will be incremented.
		We want to have at most 6 ticks on the x axis.
		If the range is less than 6 days, we will have a tick for each day.
	*/
	const tickIntervalInMillis = range / (rangeInDays < 6 ? rangeInDays : 6);

	/*
		We return an array of ticks to create the x axis.
		The first tick is the point in time of the earliest data point.
		then each consecutive tick is the previous tick plus the tick interval.
	*/
	return Array.from(
		{ length: rangeInDays < 6 ? rangeInDays + 1 : 7 },
		(_, i) => earliestDateTimeInMillis + i * tickIntervalInMillis
	);
};
