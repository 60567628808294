import React, { FC } from 'react';

import { EvLink, EvLinkTypes } from '@evinced-private/ui-common';

interface IEvChartTableLinkProps {
	value: string;
	onClick: () => void;
}

const EvChartTableLink: FC<IEvChartTableLinkProps> = ({ onClick, value }) => {
	return (
		<EvLink url="#" ariaLabel={value} linkText={value} type={EvLinkTypes.DARK} onClick={onClick} />
	);
};

export default EvChartTableLink;
