import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { isEqual } from 'lodash';

import { EvLoader, OptionType } from '@evinced-private/ui-common';

import {
	ALL_CONTENT_VIEW_ID,
	ALL_CONTENT_VIEW_LABEL
} from '../../../../components/common/ev-property-views/consts/PropertyViews';
import EvTabs from '../../../../components/common/ev-tabs/EvTabs';
import { useConfiguration } from '../../../../providers/configurationProvider/ConfigurationProvider';
import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import { SiteScannerUiToggles } from '../../../../services/LocalTogglesService';
import { PERFORMANCE_OVER_TIME_CHART_TYPE } from '../../DashboardConsts';

import { usePerformanceOverTimeData } from './hook/usePerformanceOverTimeData';
import PerformanceOverTimeChart from './PerformanceOverTimeChart';
import { PerformanceOverTimeSelectGroup } from './PerformaneOverTimeSelectGroup';
import { PerformanceOverTimeSelectGroupV2 } from './PerformaneOverTimeSelectGroupV2';

import './PerformanceOverTime.scss';

const tabOptions = [
	{ key: PERFORMANCE_OVER_TIME_CHART_TYPE.SCORE, label: 'Score' },
	{ key: PERFORMANCE_OVER_TIME_CHART_TYPE.CRITICAL_ISSUES, label: 'Critical Issues' },
	{ key: PERFORMANCE_OVER_TIME_CHART_TYPE.TOTAL_ISSUES, label: 'Total Issues' }
];

const tabs = tabOptions.reduce((obj, { key, label }) => {
	obj[key] = label;
	return obj;
}, {});

const MAX_SELECTED_VIEWS = 5;

const TIME_FRAME_OPTIONS: OptionType[] = [
	{ value: 30, label: 'Last 30 days' },
	{ value: 90, label: 'Last 3 months' },
	{ value: 180, label: 'Last 6 months' },
	{ value: 365, label: 'Last 12 months' }
];

const PerformanceOverTimeSection: FC = () => {
	const [viewsOptions, setViewsOptions] = useState<OptionType[]>([]);
	const [selectedViews, setSelectedViews] = useState<OptionType[]>([]);
	const [appliedViews, setAppliedViews] = useState<OptionType[]>([]);

	const { getToggle } = useConfiguration();

	// TIME_FRAME_OPTIONS[1] === { value: '90', label: 'Last 3 months' }
	const [selectedTimeFrame, setSelectedTimeFrame] = useState<OptionType[]>([TIME_FRAME_OPTIONS[1]]);
	const [appliedTimeFrame, setAppliedTimeFrame] = useState<OptionType[]>([TIME_FRAME_OPTIONS[1]]);

	const [appliedViewsIdsSet, setAppliedViewsIdsSet] = useState<Set<string>>(new Set());
	const [optionsDisabled, setOptionsDisabled] = useState(false);
	const {
		defaultActivePropertiesViews,
		performanceOverTimeViewMode,
		setPerformanceOverTimeViewMode
	} = useDashboard();
	const { chartType, setChartType, allViewsMap, scansMap, isLoadingScans } =
		usePerformanceOverTimeData(appliedViewsIdsSet);

	const updateAllViewsOptions = useCallback(() => {
		const options = Array.from(allViewsMap.values()).map((view) => ({
			value: view?.id,
			label: `${view?.propertyName} / ${view?.name}`
		}));
		setViewsOptions(options);
	}, [allViewsMap]);

	const initializeSelectedAndAppliedViewsOptions = useCallback(() => {
		if (defaultActivePropertiesViews?.length) {
			const selectOptions = defaultActivePropertiesViews.map((activeProperty) => ({
				value: activeProperty.view?.id || `${ALL_CONTENT_VIEW_ID} ${activeProperty.property?.id}`,
				label: `${activeProperty.property.name} / ${
					activeProperty.view?.name || ALL_CONTENT_VIEW_LABEL
				}`
			}));
			setSelectedViews(selectOptions);
			setAppliedViews(selectOptions);
		}
	}, [defaultActivePropertiesViews]);

	useEffect(() => {
		initializeSelectedAndAppliedViewsOptions();
	}, [initializeSelectedAndAppliedViewsOptions]);

	useEffect(() => {
		// disable options if max selected views reached
		if (selectedViews.length === MAX_SELECTED_VIEWS && !optionsDisabled) {
			const options = viewsOptions.map((option) => {
				const selectedIdIndex = selectedViews.findIndex((view) => view.value === option.value);
				if (selectedIdIndex === -1) {
					return {
						...option,
						isDisabled: true
					};
				}
				return option;
			});
			setViewsOptions(options);
			setOptionsDisabled(true);
		} else if (selectedViews.length < MAX_SELECTED_VIEWS && optionsDisabled) {
			const options = viewsOptions.map((option) => ({
				...option,
				isDisabled: false
			}));
			setViewsOptions(options);
			setOptionsDisabled(false);
		}
	}, [selectedViews, viewsOptions, optionsDisabled]);

	useEffect(() => {
		updateAllViewsOptions();
	}, [updateAllViewsOptions]);

	// update set of applied keys
	useEffect(() => {
		const keys = appliedViews.map((view) => view?.value as string).filter(Boolean);
		setAppliedViewsIdsSet(new Set(keys));
	}, [appliedViews]);

	const onApplyView = useCallback((): void => {
		setAppliedViewsIdsSet(() => {
			const newSet: Set<string> = new Set();
			selectedViews.forEach((view) => newSet.add(view.value as string));
			return newSet;
		});
		setAppliedViews(selectedViews);
		setAppliedTimeFrame(selectedTimeFrame);
	}, [selectedViews, selectedTimeFrame]);

	const onMultiViewSelect = useCallback(
		(values: OptionType[]): void => {
			if (values.length <= MAX_SELECTED_VIEWS) {
				setSelectedViews(values);
			}
		},
		[setSelectedViews]
	);

	const onTimeFrameSelect = useCallback(
		(value: OptionType): void => {
			setSelectedTimeFrame([value]);
		},
		[setSelectedTimeFrame]
	);

	const performanceChart = useMemo(() => {
		return tabOptions.map((option) => (
			<PerformanceOverTimeChart
				key={option.key}
				scansMap={scansMap}
				allViewsMap={allViewsMap}
				chartType={option}
				yAxisLabels={tabs}
				isNotSelected={appliedViews.length === 0}
				performanceOverTimeViewMode={performanceOverTimeViewMode}
				setPerformanceOverTimeViewMode={setPerformanceOverTimeViewMode}
				// The selected time frame is always a single value
				timeFrame={appliedTimeFrame[0].value as number}
			/>
		));
	}, [
		scansMap,
		allViewsMap,
		appliedViews,
		performanceOverTimeViewMode,
		setPerformanceOverTimeViewMode,
		appliedTimeFrame
	]);

	const renderTabsSelection = useCallback(
		() => (
			<EvTabs
				className={classNames('performance-charts-tabs', 'performance-charts-tabs-multichart')}
				options={tabOptions}
				selected={chartType}
				onSelect={setChartType}
				tabPanels={performanceChart}
			/>
		),
		[chartType, setChartType, performanceChart]
	);

	const renderChartSection = useCallback(() => {
		if (isLoadingScans && !scansMap.size) {
			return <EvLoader message="Loading..." />;
		}
		return renderTabsSelection();
	}, [isLoadingScans, scansMap, renderTabsSelection]);

	const isApplyButtonDisabled = useMemo(() => {
		return (
			isLoadingScans ||
			(isEqual(selectedViews, appliedViews) && isEqual(selectedTimeFrame, appliedTimeFrame))
		);
	}, [selectedViews, appliedViews, isLoadingScans, selectedTimeFrame, appliedTimeFrame]);

	const renderPerformanceOverTimeSelectGroup = useCallback(() => {
		return getToggle(SiteScannerUiToggles.ENABLE_PERFORMANCE_OVER_TIME_CHART_V2) ? (
			<PerformanceOverTimeSelectGroupV2
				selectedTimeFrame={selectedTimeFrame}
				onTimeFrameSelect={onTimeFrameSelect}
				TIME_FRAME_OPTIONS={TIME_FRAME_OPTIONS}
				selectedViews={selectedViews}
				onMultiViewSelect={onMultiViewSelect}
				viewsOptions={viewsOptions}
				isApplyButtonDisabled={isApplyButtonDisabled}
				onApplyView={onApplyView}
			/>
		) : (
			<PerformanceOverTimeSelectGroup
				selectedViews={selectedViews}
				onMultiViewSelect={onMultiViewSelect}
				viewsOptions={viewsOptions}
				isApplyButtonDisabled={isApplyButtonDisabled}
				onApplyView={onApplyView}
			/>
		);
	}, [
		getToggle,
		isApplyButtonDisabled,
		onApplyView,
		selectedTimeFrame,
		selectedViews,
		viewsOptions,
		onTimeFrameSelect,
		onMultiViewSelect
	]);

	return (
		<div className="performance-over-time">
			{renderChartSection()}
			<div
				className={classNames('performance-chart-details', 'performance-chart-details-multichart')}
			>
				{viewsOptions.length ? (
					renderPerformanceOverTimeSelectGroup()
				) : (
					<EvLoader message="Loading..." />
				)}
			</div>
		</div>
	);
};

export default PerformanceOverTimeSection;
