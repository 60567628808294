import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { BUTTON_TYPES, EvButton, EvLink } from '@evinced-private/ui-common';

import AzureApi from '../../api/AzureApi';
import JiraApi from '../../api/JiraApi';
import localStorageApi from '../../api/LocalStorageApi';
import { useConfiguration } from '../../providers/configurationProvider/ConfigurationProvider';
import { SiteScannerUiToggles } from '../../services/LocalTogglesService';
import { Report } from '../../types/ReportModel';
import AzureIssueCreator from '../azure-issue-creator/AzureIssueCreator';
import jiraExportHelper from '../jira-issue-creator/helpers/jira-export-helper';
import JiraIssueCreator from '../jira-issue-creator/JiraIssueCreator';

interface ITicketsSystems {
	issue: Report;
}

const TicketsSystems: FC<ITicketsSystems> = ({ issue }) => {
	const [isAzureConnected, setAzureConnected] = useState(false);
	const [isJiraConnected, setJiraConnected] = useState(false);
	const [issueToExport, setIssueToExport] = useState(null);
	const [isAzureModalOpened, setAzureModalOpened] = useState(false);
	const [isJiraModalOpened, setJiraModalOpened] = useState(false);
	const { getToggle } = useConfiguration();
	const enableAzureIntegration = getToggle(SiteScannerUiToggles.ENABLE_AZURE_INTEGRATION);

	const exportToTicketsSystem = useCallback(
		(setModalOpened: (flag: boolean) => void): void => {
			setIssueToExport(jiraExportHelper.formatIssueForExport(issue, issue?.description));
			setModalOpened(true);
		},
		[issue]
	);

	const checkIfAzureConnected = useCallback((): void => {
		const azureToken = localStorageApi.getAzureAccessToken();
		setAzureConnected(!!azureToken);
	}, []);

	const checkIfJiraConnected = useCallback((): void => {
		const jiraToken = localStorageApi.getJiraAccessToken();
		setJiraConnected(!!jiraToken);
	}, []);

	useEffect(() => {
		if (enableAzureIntegration) {
			checkIfAzureConnected();
			window.addEventListener('storage', checkIfAzureConnected);

			return () => {
				window.removeEventListener('storage', checkIfAzureConnected);
			};
		}
		return null;
	}, [checkIfAzureConnected, enableAzureIntegration]);

	useEffect(() => {
		checkIfJiraConnected();
		window.addEventListener('storage', checkIfJiraConnected);

		return () => {
			window.removeEventListener('storage', checkIfJiraConnected);
		};
	}, [checkIfJiraConnected]);

	const renderAddToButton = useCallback(
		(
			ticketSystem: string,
			isConnected: boolean,
			connectUrl: string,
			setModalOpened: (flag: boolean) => void
		) => {
			const title = `Add to ${ticketSystem}`;

			if (isConnected) {
				return (
					<EvButton
						type={BUTTON_TYPES.PRIMARY}
						title={title}
						onClick={() => exportToTicketsSystem(setModalOpened)}
						dataAutomationId="add-to-ticket-system-btn"
					>
						{title}
					</EvButton>
				);
			}
			return (
				<EvLink
					url={connectUrl}
					linkText={`Connect to ${ticketSystem}`}
					openInNewTab={true}
					className="add-to-jira-link"
				/>
			);
		},
		[exportToTicketsSystem]
	);

	const addToJiraButton = useMemo(() => {
		return renderAddToButton('Jira', isJiraConnected, JiraApi.getJiraAuthURL(), setJiraModalOpened);
	}, [renderAddToButton, isJiraConnected]);

	const addToAzureButton = useMemo(() => {
		return (
			enableAzureIntegration &&
			renderAddToButton('Azure', isAzureConnected, AzureApi.getAzureAuthURL(), setAzureModalOpened)
		);
	}, [renderAddToButton, isAzureConnected, enableAzureIntegration]);

	const renderAzureModal = (): JSX.Element => {
		if (enableAzureIntegration) {
			return (
				<AzureIssueCreator
					isOpen={isAzureModalOpened}
					closeModal={(): void => {
						setAzureModalOpened(false);
					}}
					ticketData={issueToExport}
				/>
			);
		}
		return null;
	};

	const renderJiraModal = (): JSX.Element => {
		return (
			<JiraIssueCreator
				isOpen={isJiraModalOpened}
				toggleJiraModal={setJiraModalOpened}
				closeModal={(): void => {
					setJiraModalOpened(false);
				}}
				ticketData={issueToExport}
			/>
		);
	};

	return (
		<>
			{!enableAzureIntegration && addToJiraButton}
			{addToAzureButton}
			{issueToExport && (
				<>
					{isAzureModalOpened && renderAzureModal()}
					{isJiraModalOpened && renderJiraModal()}
				</>
			)}
		</>
	);
};

export default TicketsSystems;
