import React, { FunctionComponent } from 'react';

import {
	BarDatum,
	EVINCED_SEVERITIES_IDS,
	EvincedColors,
	EvRaceBar
} from '@evinced-private/ui-common';

import chartsHelper from '../../../helpers/ChartsHelper';
import EvChartTableLink from '../../common/ev-chart-table-link/EvChartTableLink';

import './CriticalComponentsRaceBar.scss';

interface IEvincedAdvantageBarProps {
	componentsWithCritical: BarDatum[];
	totalCriticalIssues: number;
	propertyId: string;
	scanId: string;
	className?: string;
	maxHeight?: number;
	onTableLinkClick?: (componentId: string) => void;
}

const CriticalComponentsRaceBar: FunctionComponent<IEvincedAdvantageBarProps> = ({
	componentsWithCritical,
	totalCriticalIssues,
	className,
	propertyId,
	scanId,
	maxHeight = 320,
	onTableLinkClick
}) => {
	const topTenComponents = chartsHelper.getTopTenComponents(componentsWithCritical);
	const { barData, percentCovered } = chartsHelper.calcDataForComponentsRaceGraph(
		topTenComponents,
		totalCriticalIssues,
		propertyId,
		scanId
	);

	const fieldFormatter = (componentId: string): React.ReactNode => {
		return <EvChartTableLink onClick={() => onTableLinkClick(componentId)} value={componentId} />;
	};

	const title = 'Breakdown by components';
	return (
		<EvRaceBar
			title={title}
			data={barData}
			description={`${barData.length} Components cover ${percentCovered}% of critical issues`}
			itemTypeLabel="Component"
			itemTooltipPercentDescription="of critical issues"
			className={className}
			color={EvincedColors.newSeverityColors[EVINCED_SEVERITIES_IDS.CRITICAL]}
			maxHeight={maxHeight}
			headerTooltipProps={{
				tooltipText:
					'The graph indicates the % of critical issues out of total critical issues that appear in each component. This graph gives an overview on the top components require fixing based on the total number of critical issues. Fixing these components will have the most immediate impact on improving end user experience.',
				tooltipTitle: 'What is critical issues breakdown by components',
				tooltipPlacement: 'right'
			}}
			tableProps={{
				columnsPattern: [
					{ name: 'Component', field: 'id', formatter: fieldFormatter },
					{ name: 'Issues Percentage', field: 'percent' }
				]
			}}
			showLabels={false}
		/>
	);
};

export default CriticalComponentsRaceBar;
