const MIN_URL_LENGTH = 6;

export type UrlValidationResult = {
	isValid: boolean;
	errorMessages: string[];
};

/*
** (https?:\/\/)? -> optional http:// or https:// at the beginning
** ([a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,6})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}) ->
	 Matches either a domain name or IPv4 address
** (:[0-9]{1,5})? ->
	 Matches an optional port number specified by a colon followed by one to five digits.
** (\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)? ->
	Matches an optional path of the URL, starting with a forward slash.
*/
export const isURLValid = (str: string): boolean => {
	// eslint-disable-next-line no-useless-escape
	const pattern =
		// eslint-disable-next-line no-useless-escape
		/^(https?:\/\/)?([a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,6})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:[0-9]{1,5})?(\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
	return !!pattern.test(str);
};

const validateUrlFormat = (url: string): UrlValidationResult => {
	const result = { isValid: true, errorMessages: [] };
	if (!url) {
		result.isValid = false;
		result.errorMessages.push('Url cannot be empty');
		return result;
	}

	if (url.length < MIN_URL_LENGTH) {
		result.isValid = false;
		result.errorMessages.push(`Please enter at least ${MIN_URL_LENGTH} characters`);
	}
	if (url.trim().includes(' ')) {
		result.isValid = false;
		result.errorMessages.push('Spaces are not allowed');
	}
	if (!isURLValid(url)) {
		result.isValid = false;
		result.errorMessages.push('Invalid URL');
	}
	return result;
};

export default { validateUrlFormat };
