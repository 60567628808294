import ErrorLoadPageIcon from '../components/icons/ErrorLoadPageIcon.svg';
import GraphIcon from '../components/icons/GraphIcon.svg';

export const enum ERROR_TYPE {
	FAILED = 'failed',
	NO_DATA = 'noData',
	NOT_SELECTED = 'notSelected',
	NO_DATA_IN_TIME_FRAME = 'noDataInTimeFrame'
}

export const chartsDefaultErrorsData = {
	failed: {
		title: 'Failed to load data',
		description: [
			'Please try refreshing the page.',
			"If that doesn't work, please contact Evinced customer support."
		],
		icon: ErrorLoadPageIcon
	},
	noData: {
		title: 'No data',
		description: 'Try modifying the Views to see data.',
		icon: GraphIcon
	}
};
