import React, { FC } from 'react';

import { EvIcon } from '@evinced-private/ui-common';

import GeneralError from '../../components/general-error/GeneralError';

import './PropertyErrLoadViewMsg.scss';

interface ILoadErrorMsgLayout {
	mainTitle: string;
	secondaryMsg: string | string[];
	icon: SVGElement;
}
const LoadErrorMsgLayout: FC<ILoadErrorMsgLayout> = ({
	mainTitle,
	secondaryMsg,
	icon,
	children
}) => {
	return (
		<div className="error-layout">
			<GeneralError title="" displayContactInfo={false}>
				<div className="msg-content">
					<EvIcon icon={icon} title={mainTitle} />
					<div className="main-title">{mainTitle}</div>
					<div className="secondary-msg">
						{Array.isArray(secondaryMsg)
							? secondaryMsg.map((line) => (
									<div aria-live="polite" role="status" key={line}>
										{line}
										<br />
									</div>
								))
							: secondaryMsg}
					</div>
				</div>
				{children}
			</GeneralError>
		</div>
	);
};

export default LoadErrorMsgLayout;
