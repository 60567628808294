import React, { FC, useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import {
	EvIcon,
	EvInfoTooltip,
	EvSection,
	EvSelect,
	EvTextInput,
	EvTitle,
	TEXT_INPUT_TYPES
} from '@evinced-private/ui-common';

import { ILoginConfiguration } from '../../../../interfaces/LoginConfiguration';
import LOGIN_METHOD from '../../../../interfaces/LoginMethod';
import { IProperty } from '../../../../interfaces/Property';
import { IPropertyError } from '../../../../interfaces/PropertyError';
import { SetPropertyType } from '../../../../pages/property-settings/PropertySettingsPageTypes';
import { useConfiguration } from '../../../../providers/configurationProvider/ConfigurationProvider';
import { SiteScannerUiToggles } from '../../../../services/LocalTogglesService';
import { OptionType } from '../../../../types/OptionType';
import WarningTriangleIcon from '../../../icons/WarningTriangleIcon.svg';

import './LoginSettings.scss';

const PASSWORD_MASK = '********';
const LOGIN_SETTINGS_TITLE = 'login-settings-title';
const LOGIN_SETTINGS_DESCRIPTION = 'login-settings-description';
const INVALID_COOKIE_ERROR = `Cookies are of incorrect format.
            Cookies are expected to be a valid json list where each entry is a valid puppeteer cookie:
            https://pptr.dev/#?product=Puppeteer&version=v10.0.0&show=api-pagesetcookiecookies`;
const COOKIES_CONSENT_FIELD_TOOLTIP =
	"Please enter the CSS selector for the 'Accept' button in this field. Example: '#cookies-accept-button'";
enum LoginMethodType {
	NOT_REQUIRED = 'Not Required',
	FORM_LOGIN = 'Form login',
	COOKIES = 'Login with cookies'
}

interface ILoginSettingsProps {
	originalProperty: IProperty;
	property: IProperty;
	setProperty: SetPropertyType;
	propertyError?: IPropertyError;
}

const LoginSettings: FC<ILoginSettingsProps> = ({
	originalProperty,
	property,
	setProperty,
	propertyError
}: ILoginSettingsProps) => {
	const { getToggle } = useConfiguration();
	const showCookiesConsentSelector = getToggle(
		SiteScannerUiToggles.ENABLE_COOKIES_CONSENT_SELECTOR
	);

	// currently we don't have a scan configuration screen, and since login configuration should be
	// the same for both scans and crawl, we can take this configuration from either scan
	// or crawl configurations.
	const scanLoginConfiguration = property.scanConfiguration?.loginConfiguration;
	const cookieConsentConfiguration =
		property.urlSetConfiguration?.discoveryConfiguration?.cookieConsentConfiguration ||
		property.scanConfiguration?.cookieConsentConfiguration;

	const initialCookies = scanLoginConfiguration?.cookies
		? JSON.stringify(scanLoginConfiguration.cookies)
		: null;
	let initialLoginMethod: LoginMethodType;
	switch (scanLoginConfiguration?.loginMethod) {
		case LOGIN_METHOD.LOGIN_FORM:
			initialLoginMethod = LoginMethodType.FORM_LOGIN;
			break;
		case LOGIN_METHOD.COOKIES:
			initialLoginMethod = LoginMethodType.COOKIES;
			break;
		default:
			initialLoginMethod = LoginMethodType.NOT_REQUIRED;
	}

	const loginFormConfiguration = scanLoginConfiguration?.loginFormConfiguration;
	const originalScanConfigPassword =
		originalProperty?.scanConfiguration?.loginConfiguration?.loginFormConfiguration?.password;
	const originalUrlSetConfigPassword =
		originalProperty?.urlSetConfiguration?.discoveryConfiguration?.loginConfiguration
			?.loginFormConfiguration?.password;
	const [loginMethodType, setLoginMethodType] = useState(initialLoginMethod);
	const [loginURL, setLoginURL] = useState(loginFormConfiguration?.loginURL);
	const [userName, setUserName] = useState(loginFormConfiguration?.userName);
	const [usernameSelector, setusernameSelector] = useState(
		loginFormConfiguration?.usernameSelector
	);
	const [password, setPassword] = useState(loginFormConfiguration?.password ? PASSWORD_MASK : '');
	const [passwordSelector, setPasswordSelector] = useState(
		loginFormConfiguration?.passwordSelector
	);
	const [loginButtonSelector, setLoginButtonSelector] = useState(
		loginFormConfiguration?.loginButtonSelector
	);
	const [cookiesConsentSelector, setCookiesConsentSelector] = useState(
		cookieConsentConfiguration?.cookieButtonSelector
	);
	const [cookies, setCookies] = useState(initialCookies);
	const [cookiesError, setCookieError] = useState(null);

	const setLoginConfigurationToProperty = useCallback(
		(
			loginConfiguration?: ILoginConfiguration,
			urlSetLoginConfiguration?: ILoginConfiguration
		): void => {
			setProperty((property: IProperty): IProperty => {
				const propertyClone: IProperty = _.cloneDeep(property);
				const scanConfiguration = propertyClone.scanConfiguration || {};
				propertyClone.scanConfiguration = { ...scanConfiguration, loginConfiguration };
				// since this is called as a part of the useEffect hook, which also includes
				// componentUnmount,
				// it's possible to get to this code when switching to list mode. This is a good thing,
				// since we want to set  scanConfiguration.loginConfiguration to null in this case,
				// but urlSetConfiguration.discoveryConfiguration is null, so we need to make sure
				// we're not getting a null pointer exception
				const cookieConsentConfiguration = { cookieButtonSelector: cookiesConsentSelector };
				if (propertyClone.urlSetConfiguration.discoveryConfiguration) {
					propertyClone.urlSetConfiguration.discoveryConfiguration.loginConfiguration =
						urlSetLoginConfiguration;
					if (showCookiesConsentSelector) {
						propertyClone.urlSetConfiguration.discoveryConfiguration.cookieConsentConfiguration =
							cookieConsentConfiguration;
						propertyClone.scanConfiguration.cookieConsentConfiguration = cookieConsentConfiguration;
					}
				} else if (showCookiesConsentSelector) {
					propertyClone.scanConfiguration.cookieConsentConfiguration = cookieConsentConfiguration;
				}

				return propertyClone;
			});
		},
		[setProperty, cookiesConsentSelector, showCookiesConsentSelector]
	);

	const areCookiesValid = useCallback((): boolean => {
		if (!cookies) {
			return false;
		}
		try {
			JSON.parse(cookies);
			return true;
		} catch (err) {
			return false;
		}
	}, [cookies]);

	const updateLoginForm = useCallback((): void => {
		const createLoginConfiguration = (originalPassword: string): ILoginConfiguration => {
			return {
				loginMethod: LOGIN_METHOD.LOGIN_FORM,
				loginFormConfiguration: {
					loginURL,
					usernameSelector,
					userName,
					passwordSelector,
					// original password is masked in the input field initially. When updating the
					// configuration value, use the original value if masked - otherwise use input value.
					password: password === PASSWORD_MASK ? originalPassword : password,
					loginButtonSelector
				}
			};
		};
		const scanLoginConfiguration = createLoginConfiguration(originalScanConfigPassword);
		const urlSetLoginConfiguration = createLoginConfiguration(originalUrlSetConfigPassword);
		setLoginConfigurationToProperty(scanLoginConfiguration, urlSetLoginConfiguration);
	}, [
		loginButtonSelector,
		loginURL,
		password,
		passwordSelector,
		setLoginConfigurationToProperty,
		userName,
		usernameSelector,
		originalScanConfigPassword,
		originalUrlSetConfigPassword
	]);

	const updateLoginCookies = useCallback((): void => {
		let propertyCookies;
		if (areCookiesValid()) {
			setCookieError(null);
			propertyCookies = JSON.parse(cookies);
		} else {
			setCookieError(INVALID_COOKIE_ERROR);
			propertyCookies = null;
		}
		const loginConfiguration: ILoginConfiguration = {
			loginMethod: LOGIN_METHOD.COOKIES,
			cookies: propertyCookies
		};
		setLoginConfigurationToProperty(loginConfiguration);
	}, [areCookiesValid, cookies, setLoginConfigurationToProperty]);

	const updateLoginNotRequired = useCallback((): void => {
		setLoginConfigurationToProperty(null);
	}, [setLoginConfigurationToProperty]);

	useEffect(() => {
		if (loginMethodType === LoginMethodType.NOT_REQUIRED) {
			updateLoginNotRequired();
		} else if (loginMethodType === LoginMethodType.FORM_LOGIN) {
			updateLoginForm();
		} else if (loginMethodType === LoginMethodType.COOKIES) {
			updateLoginCookies();
		}
	}, [
		loginMethodType,
		initialLoginMethod,
		updateLoginCookies,
		updateLoginForm,
		updateLoginNotRequired
	]);

	const renderMoreInfo = (): JSX.Element => {
		return (
			<>
				Choosing and setting up a login method will perform a login using the selected method, and
				allows scanning and crawling behind login pages.
			</>
		);
	};

	const renderSectionHeader = (): JSX.Element => {
		return (
			<div className="section-header">
				<div className="title">
					<EvTitle
						titleText="Login Method"
						MoreInfoContent={renderMoreInfo()}
						id={LOGIN_SETTINGS_TITLE}
						descriptionId={LOGIN_SETTINGS_DESCRIPTION}
					/>
				</div>
			</div>
		);
	};

	const renderFormLogin = (): JSX.Element => {
		return (
			<div className="login-form">
				<div className="login-form-row">
					<label className="form-label">Login URL</label>
					<div className="form-field">
						<EvTextInput
							ariaLabel="Login URL"
							isRequired
							value={loginURL}
							placeholder="Enter login URL"
							onChange={setLoginURL}
						/>
					</div>
				</div>
				<div className="login-form-row">
					<label className="form-label">User Name</label>
					<div className="form-field">
						<EvTextInput
							isRequired
							value={userName}
							placeholder="Enter user name"
							onChange={setUserName}
						/>
					</div>
				</div>
				<div className="login-form-row">
					<label className="form-label">User Name Selector</label>
					<div className="form-field">
						<EvTextInput
							isRequired
							value={usernameSelector}
							placeholder="Enter user name CSS selector"
							onChange={setusernameSelector}
						/>
					</div>
					<div className="form-tooltip">
						<EvInfoTooltip title="" tooltipPlace="left">
							It is strongly recommended to use an id or a classname. For e.g: #userNameInputBox or
							.userNameInputBox
						</EvInfoTooltip>
					</div>
				</div>
				<div className="login-form-row">
					<label className="form-label"> Password </label>
					<div className="form-field">
						<EvTextInput
							isRequired
							value={password}
							type={TEXT_INPUT_TYPES.PASSWORD}
							placeholder="Enter password"
							onChange={setPassword}
						/>
					</div>
				</div>
				<div className="login-form-row">
					<label className="form-label"> Password Selector </label>
					<div className="form-field">
						<EvTextInput
							isRequired
							value={passwordSelector}
							placeholder="Enter password CSS selector"
							onChange={setPasswordSelector}
						/>
					</div>
					<div className="form-tooltip">
						<EvInfoTooltip title="" tooltipPlace="left">
							It is strongly recommended to use an id or a classname. For e.g: #passwordInputBox or
							.passwordInputBox
						</EvInfoTooltip>
					</div>
				</div>
				<div className="login-form-row">
					<label className="form-label"> Login Button Selector </label>
					<div className="form-field">
						<EvTextInput
							isRequired
							value={loginButtonSelector}
							placeholder="Enter login button CSS selector"
							onChange={setLoginButtonSelector}
						/>
					</div>
				</div>
				{showCookiesConsentSelector && (
					<div className="login-form-row">
						<label className="form-label"> Cookies Consent Button Selector </label>
						<div className="form-field">
							<EvTextInput
								isRequired
								value={cookiesConsentSelector}
								placeholder="Enter cookies consent button CSS selector"
								onChange={setCookiesConsentSelector}
							/>
						</div>
						<div className="form-tooltip">
							<EvInfoTooltip title="Cookies consent button selector" tooltipPlace="left">
								{COOKIES_CONSENT_FIELD_TOOLTIP}
							</EvInfoTooltip>
						</div>
					</div>
				)}
			</div>
		);
	};

	const renderCookiesLogin = (): JSX.Element => {
		return (
			<div className="login-cookies">
				<div className="login-cookies-row">
					<label className="form-label">Cookies</label>
					<div className="form-field">
						<EvTextInput
							ariaLabel="cookies"
							isRequired
							value={cookies}
							placeholder="Enter cookies"
							error={cookiesError}
							onChange={setCookies}
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderLoginInfo = (): JSX.Element => {
		if (loginMethodType === LoginMethodType.FORM_LOGIN) {
			return renderFormLogin();
		}
		if (loginMethodType === LoginMethodType.COOKIES) {
			return renderCookiesLogin();
		}
		return null;
	};

	const getDropDownOptions = (): OptionType[] => {
		const options = [
			{
				label: 'No login required',
				value: LoginMethodType.NOT_REQUIRED
			},
			{
				label: 'Form login',
				value: LoginMethodType.FORM_LOGIN
			},
			{
				label: 'Login with cookies',
				value: LoginMethodType.COOKIES
			}
		];

		return options;
	};

	const renderChooseLoginMethod = (): JSX.Element => {
		const options = getDropDownOptions();
		const selectedOption = options.find((o) => o.value === loginMethodType);
		return (
			<div className="choose-login-method-dropdown">
				<EvSelect
					isMulti={false}
					value={[selectedOption]}
					options={options}
					placeholder="Select Login Method"
					onChange={(selectedItem) => {
						setLoginMethodType(selectedItem.value);
					}}
				/>
			</div>
		);
	};

	const renderLoginConfigurationWarning = (): JSX.Element => {
		const hasScanUserInteractionConfigurations =
			!!originalProperty?.scanConfiguration?.scraperConfiguration?.userInteractionConfiguration
				?.length;
		const hasUrlSetUserInteractionConfigurations =
			!!originalProperty?.urlSetConfiguration?.discoveryConfiguration?.userInteractionConfiguration
				?.length;

		return (
			loginMethodType !== LoginMethodType.NOT_REQUIRED &&
			(hasScanUserInteractionConfigurations || hasUrlSetUserInteractionConfigurations) && (
				<div className="login-config-warning">
					<EvIcon icon={WarningTriangleIcon} small />
					Login settings are already set for this property. Contact support for assistance.
				</div>
			)
		);
	};

	const renderSectionBody = (): JSX.Element => {
		return (
			<div className="section-body">
				{renderChooseLoginMethod()}
				{renderLoginConfigurationWarning()}
				{renderLoginInfo()}
			</div>
		);
	};

	const renderError = (): JSX.Element => {
		return propertyError && <div className="tab-error">{propertyError.message}</div>;
	};

	return (
		<EvSection
			className="login-settings-section"
			ariaLabelledby={LOGIN_SETTINGS_TITLE}
			ariaDescribedby={LOGIN_SETTINGS_DESCRIPTION}
		>
			<div className="login-settings-content">
				{renderSectionHeader()}
				{renderSectionBody()}
				{renderError()}
			</div>
		</EvSection>
	);
};

export default LoginSettings;
