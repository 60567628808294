import React, { FC, useCallback, useMemo } from 'react';

import {
	BarDatum,
	EvBarChart,
	EvChartTooltip,
	FormatHelper,
	PatternData
} from '@evinced-private/ui-common';

import { ERROR_TYPE } from '../../../consts/errors';
import { EVINCED_SEVERITIES_BY_LABEL } from '../../../consts/severityIDs';
import ColorsHelper from '../../../helpers/charts/ColorsHelper';
import ChartsHelper from '../../../helpers/ChartsHelper';
import IdFormatterHelper from '../../../helpers/IdFormatterHelper';
import { SeverityData } from '../../../types/OverviewTypes';
import EvChartTableLink from '../../common/ev-chart-table-link/EvChartTableLink';
import { ChartErrorView } from '../ChartErrorView';

const MAX_ITEMS_PER_SEVERITY = 5;
const MIN_ITEMS_TO_DISPLAY = 5;
const MIN_PERCENT_FOR_DISPLAY_FALLBACK = 3;
const MIN_PERCENT_FOR_DISPLAY = 10;
const DEFAULT_CHART_HEIGHT = 450;
const DEFAULT_CHART_WIDTH = 501;

interface IPagesBySeverityBarProps {
	pagesScanned: number;
	onBarItemClick: (stateKey: string, stateValue: string | number) => void;
	pagesSeverities?: SeverityData[];
}

const PagesByIssueTypeBar: FC<IPagesBySeverityBarProps> = ({
	pagesScanned,
	onBarItemClick,
	pagesSeverities
}) => {
	const getBarData = useCallback(
		(minPercentForDisplay): BarDatum[] => {
			const barData = [];
			pagesSeverities.forEach((severityData) => {
				const enrichedIssueTypes = ChartsHelper.enrichIssueTypesWithPagesInfoV2(
					severityData,
					pagesScanned
				);
				const severityIssueTypes = enrichedIssueTypes.filter(
					(item) => item.severity === severityData.severity
				);
				const sorted = severityIssueTypes.sort((a, b) => b.percent - a.percent);
				const onlyBigIssues = sorted.filter(({ percent }) => percent > minPercentForDisplay);
				const limited = onlyBigIssues.splice(0, MAX_ITEMS_PER_SEVERITY);
				barData.push(...limited);
			});
			return barData.map((bar) => ({
				id: IdFormatterHelper.formatTextToId(bar.id),
				label: bar.type,
				percent: bar.percent,
				severity: bar.severity
			}));
		},
		[pagesSeverities, pagesScanned]
	);

	const tooltipRenderer = (d): JSX.Element => {
		return <EvChartTooltip label={`${d.data.label} (${d.data.severity})`} value={`${d.value}%`} />;
	};

	const barData = useMemo(() => {
		let barData = getBarData(MIN_PERCENT_FOR_DISPLAY);
		// if barData is small, reduce the min percent for display
		if (barData.length <= MIN_ITEMS_TO_DISPLAY) {
			barData = getBarData(MIN_PERCENT_FOR_DISPLAY_FALLBACK);
		}
		return barData;
	}, [getBarData]);

	const patterns = useMemo(() => {
		return barData.map(
			(bar) =>
				({
					id: bar.id,
					label: bar.label,
					color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_BY_LABEL[bar.severity]]
				}) as PatternData
		);
	}, [barData]);

	const srSummaryData = useMemo(() => {
		return barData.map(
			(bar) =>
				({
					id: bar.id,
					label: bar.label,
					count: `${bar.percent}%`
				}) as PatternData
		);
	}, [barData]);

	const typeFieldFormatter = (cell: string): React.ReactNode => {
		return <EvChartTableLink onClick={() => onBarItemClick('type', cell)} value={cell} />;
	};

	const errorType: ERROR_TYPE | null = useMemo(() => {
		if (!pagesSeverities?.length) {
			return ERROR_TYPE.FAILED;
		}
		if (barData.length === 0) {
			return ERROR_TYPE.NO_DATA;
		}
		return null;
	}, [pagesSeverities, barData]);

	const chartTitle = 'Pages impacted by issue type';

	return (
		<EvBarChart
			title={chartTitle}
			height={!errorType && DEFAULT_CHART_HEIGHT}
			width={!errorType && DEFAULT_CHART_WIDTH}
			data={barData}
			patterns={patterns}
			srSummaryData={srSummaryData}
			className="pages-by-issue-type"
			keys={['percent']}
			indexBy="id"
			isFocusable
			onBarClick={(barData) => {
				onBarItemClick('type', barData.data.label);
			}}
			withLegend={false}
			tableProps={{
				dataType: 'Issue type',
				columnsPattern: [
					{ name: 'Issue type', field: 'label', formatter: typeFieldFormatter },
					{ name: 'Issues Percentage', field: 'percent' }
				],
				defaultSortByField: 'label'
			}}
			barDesignProps={{
				margin: { top: 20, right: 0, bottom: 150, left: 80 },
				gridYValues: [0, 20, 40, 60, 80, 100],
				enableLabel: false,
				tooltip: tooltipRenderer,
				animate: false,
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickValues: [0, 20, 40, 60, 80, 100],
					tickPadding: 10,
					tickSize: 0,
					format: FormatHelper.addPercentSign
				},
				axisBottom: {
					tickRotation: -45,
					tickPadding: 10,
					format: (id) => barData.find((item) => item.id === id)?.label,
					renderTick: undefined
				}
			}}
			headerTooltipProps={{
				tooltipText:
					'The graph indicates the distribution of issues across pages. Each vertical graph represents the % of impacted pages by different issue types. The colors represents the Severity of the issue type.',
				tooltipTitle: `What is ${chartTitle}`,
				tooltipPlacement: 'right'
			}}
			isError={!!errorType}
			renderErrorState={() => <ChartErrorView errorType={errorType} />}
		/>
	);
};

export { PagesByIssueTypeBar };
