import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';

import { ITenant, ProductType, TenantProduct } from '../../interfaces/Tenant';
import { IUser, ROLE_TYPE_ENTITY } from '../../interfaces/User';
import AuthenticationService from '../../services/AuthenticationService';
import TenantsService from '../../services/TenantsService';
import UserService from '../../services/UserService';

interface IUserTenant {
	user: IUser;
	tenant: ITenant;
	hasAdminPermissions: () => boolean;
	isReadOnlyUser: () => boolean;
	updateUserTenant: () => Promise<void>;
	getProductIdByType: (type: ProductType) => string;
}

const UserTenantContext = createContext<IUserTenant>(null);

const UserTenantProvider: FC = ({ children }) => {
	const [user, setUser] = useState<IUser>(null);
	const [tenant, setTenant] = useState<ITenant>(null);
	const isUserLoading = useRef<boolean>(false);
	const isTenantLoading = useRef<boolean>(false);

	const getProductIdByType = useCallback(
		(productType: ProductType): string => {
			const product = tenant?.products?.find(
				(product: TenantProduct) => product.type === productType
			);
			return product?.id;
		},
		[tenant]
	);

	const updateUserTenant = useCallback(async (): Promise<void> => {
		if (AuthenticationService.isLoggedIn()) {
			if (!user && !isUserLoading.current) {
				isUserLoading.current = true;
				const currentUser = await UserService.getUserMetadata();
				setUser(currentUser);
				isUserLoading.current = false;
			}
			if (!tenant && !isTenantLoading.current) {
				isTenantLoading.current = true;
				const currentTenant = await TenantsService.getUserTenant();
				setTenant(currentTenant);
				isTenantLoading.current = false;
			}
		}
	}, [user, tenant]);

	const onLoad = useCallback(() => updateUserTenant(), [updateUserTenant]);

	const hasAdminPermissions = useCallback(
		(): boolean => user && user.role?.type === ROLE_TYPE_ENTITY.TENANT_ADMIN,
		[user]
	);

	const isReadOnlyUser = useCallback(
		(): boolean => !user || (user && user.role?.type === ROLE_TYPE_ENTITY.READ_ONLY),
		[user]
	);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	const value = useMemo(() => {
		return {
			user,
			tenant,
			hasAdminPermissions,
			updateUserTenant,
			isReadOnlyUser,
			getProductIdByType
		};
	}, [user, tenant, hasAdminPermissions, updateUserTenant, isReadOnlyUser, getProductIdByType]);

	return <UserTenantContext.Provider value={value}>{children}</UserTenantContext.Provider>;
};

const useUserTenant = (): IUserTenant => useContext(UserTenantContext);

export { UserTenantProvider, useUserTenant };
