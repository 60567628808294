import React, { FC } from 'react';

import { chartsDefaultErrorsData, ERROR_TYPE } from '../../consts/errors';
import LoadErrorMsgLayout from '../../pages/property/LoadErrorMsgLayout';

interface IChartErrorViewProps {
	errorType: ERROR_TYPE;
}

export const ChartErrorView: FC<IChartErrorViewProps> = ({ errorType }) => {
	return (
		<LoadErrorMsgLayout
			mainTitle={chartsDefaultErrorsData[errorType].title}
			secondaryMsg={chartsDefaultErrorsData[errorType].description}
			icon={chartsDefaultErrorsData[errorType].icon}
		/>
	);
};
