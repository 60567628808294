import React, { FunctionComponent } from 'react';

import pluralize from 'pluralize';

import {
	BarDatum,
	EVINCED_SEVERITIES_IDS,
	EvincedColors,
	EvRaceBar
} from '@evinced-private/ui-common';

import chartsHelper from '../../../helpers/ChartsHelper';
import EvChartTableLink from '../../common/ev-chart-table-link/EvChartTableLink';

import './CriticalComponentsRaceBar.scss';

interface IEvincedAdvantageBarProps {
	componentsWithCritical: BarDatum[];
	totalCriticalIssues: number;
	propertyId: string;
	scanId: string;
	className?: string;
	maxHeight?: number;
	onTableLinkClick?: (componentId: string) => void;
}

const CriticalComponentsRaceBarV3: FunctionComponent<IEvincedAdvantageBarProps> = ({
	componentsWithCritical,
	totalCriticalIssues,
	className,
	propertyId,
	scanId,
	maxHeight = 320,
	onTableLinkClick
}) => {
	const topTenComponents = chartsHelper.getTopTenComponents(componentsWithCritical);
	const { barData, percentCovered } = chartsHelper.calcDataForComponentsRaceGraph(
		topTenComponents,
		totalCriticalIssues,
		propertyId,
		scanId
	);

	const fieldFormatter = (componentId: string): React.ReactNode => {
		return <EvChartTableLink onClick={() => onTableLinkClick(componentId)} value={componentId} />;
	};

	const title = 'Breakdown by components';

	const isError = barData.length === 0;

	const renderErrorState = (): JSX.Element => {
		return <p className="ev-chart-error-message">No grouped critical issues found.</p>;
	};

	return (
		<EvRaceBar
			title={title}
			data={barData}
			description={
				isError
					? ''
					: `Resolve ${percentCovered}% of critical issues - by fixing ${barData.length} ${pluralize('component', barData.length)}`
			}
			itemTypeLabel="Component"
			itemTooltipPercentDescription="of critical issues"
			className={className}
			color={EvincedColors.newSeverityColors[EVINCED_SEVERITIES_IDS.CRITICAL]}
			maxHeight={maxHeight}
			headerTooltipProps={{
				tooltipText:
					'An overview of the top components needing fixes, based on the total number of critical issues. Resolving an issue within a component group may potentially resolve the entire group.',
				tooltipTitle: 'What is critical issues breakdown by components',
				tooltipPlacement: 'right'
			}}
			tableProps={{
				columnsPattern: [
					{ name: 'Component', field: 'id', formatter: fieldFormatter },
					{ name: 'Issues Percentage', field: 'percent' }
				]
			}}
			showLabels={false}
			isError={isError}
			renderErrorState={isError && renderErrorState}
			disabledSwitcher={isError}
		/>
	);
};

export { CriticalComponentsRaceBarV3 };
