import React from 'react';

import _ from 'lodash';

import {
	EvEllipsedTextWithTooltip,
	EvTableColumn,
	EvTableOptions,
	MultilineTextCellFormatter,
	ScreenshotDetails,
	SORT_ORDER,
	TableSortOption
} from '@evinced-private/ui-common';

import NotGroupedComponent from '../../consts/NotGroupedComponent';
import { Issue } from '../../interfaces/Issue';
import { ScanTableDefinition } from '../scan-analysis/base/BaseScanViewV2';
import ScreenshotCellFormatter from '../scan-analysis/table-formatters/screenshot-cell-formatter/ScreenshotCellFormatter';
import ShowDetailsCellFormatter from '../show-details-cell-formatter/ShowDetailsCellFormatter';

const getDefaultSort = (): TableSortOption => ({
	dataField: 'url',
	order: SORT_ORDER.DESC
});

const getColumns = (scanResults: Issue[]): EvTableColumn[] => {
	const scanId = scanResults?.[0]?.scanId;
	return [
		{
			dataField: 'url',
			text: 'Page',
			style: { width: '285px', maxWidth: '285px' },
			headerStyle: { width: '285px' },
			sort: true
		},
		{
			dataField: 'severity',
			text: 'Severity',
			style: { width: '142px', maxWidth: '142px' },
			headerStyle: { width: '142px' },
			sort: true,
			formatter: (severity: string) => {
				return (
					<EvEllipsedTextWithTooltip text={severity} maxWidth={120} showTooltipOnlyOnEllipsed />
				);
			}
		},
		{
			dataField: 'type',
			text: 'Issue type',
			style: { width: '142px', maxWidth: '142px' },
			headerStyle: { width: '142px' },
			formatter: (type: string) => {
				return <EvEllipsedTextWithTooltip text={type} maxWidth={120} showTooltipOnlyOnEllipsed />;
			}
		},
		{
			dataField: 'selectors',
			text: 'Selector',
			style: { width: '200px', maxWidth: '200px' },
			headerStyle: { width: '200px' },
			formatter: (selectors: string[]) => {
				return (
					<MultilineTextCellFormatter items={selectors} itemType="selector" showTooltip={true} />
				);
			}
		},
		{
			dataField: 'components',
			text: 'Component',
			style: { width: '100px', maxWidth: '100px' },
			headerStyle: { width: '100px' },
			formatter: (componentIds: string[]) => {
				return <MultilineTextCellFormatter items={componentIds} itemType="component" showTooltip />;
			}
		},
		{
			dataField: 'screenShotPromise',
			text: 'Screenshot',
			style: { width: '182px', maxWidth: '182px' },
			classes: 'image-cell-td',
			headerStyle: { width: '182px' },
			formatter: (cell, issue: IssuesTableRowData) => {
				return (
					<ScreenshotCellFormatter
						cellDescriptor={`${issue.url} ${issue.type}`}
						screenshotDetails={issue.screenshotDetails}
					/>
				);
			}
		},
		{
			dataField: '',
			text: '',
			style: { width: '56px', maxWidth: '56px' },
			headerStyle: { width: '56px' },
			formatter: (cell, row: IssuesTableRowData) => {
				return (
					<ShowDetailsCellFormatter issue={row} rowId={`${row.url} ${row.type}`} scanId={scanId} />
				);
			}
		}
	];
};

const getOptions = (): EvTableOptions => {
	return {
		title: 'Scan comparison issues',
		caption: 'Scan comparison issues table',
		pagination: true,
		remote: true,
		dataType: 'issues',
		defaultSorted: [getDefaultSort()]
	};
};

type IssuesTableRowData = {
	id: string;
	url: string;
	type: string;
	components: string[];
	severity: string;
	selectors: string[];
	screenshotDetails: ScreenshotDetails;
};

const getTableData = (issues: Issue[]): IssuesTableRowData[] => {
	return issues.map((issue) => {
		const components = _.uniq(
			issue.elements.map((e) => {
				return e.componentId === NotGroupedComponent.ID
					? NotGroupedComponent.DISPLAY_NAME
					: e.componentId;
			})
		);

		const firstElement = issue.elements[0];
		const uniqueElementsBySelector = _.uniqBy(issue.elements, 'selector');
		const selectors = uniqueElementsBySelector.map((element) => element.selector);
		const boundingBoxes = uniqueElementsBySelector.map((element) => element.boundingBox);
		return {
			id: issue.id,
			url: firstElement.pageUrl,
			type: issue.type.name,
			components,
			severity: issue.severity.name,
			selectors,
			screenshotDetails: {
				pageScreenshotUrl: issue.pageScreenshot,
				boundingBoxes
			}
		};
	});
};

const getTableDefinition = (
	scanResults: Issue[],
	totalCount: number,
	totalPaginationableResults: number
): ScanTableDefinition => {
	const result: ScanTableDefinition = {
		columns: getColumns(scanResults),
		options: getOptions(),
		data: getTableData(scanResults),
		totalCount,
		totalPaginationableResults
	};
	return result;
};
export default { getTableDefinition, getDefaultSort };
