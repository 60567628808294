import _ from 'lodash';
import moment from 'moment';

const shortMonth = 'MMM D, YYYY, h:mm a';
const longMonth = 'MMMM D, YYYY, h:mm a';
const shortMonthWithoutTime = 'MMM D, YYYY';

export function formatDate(dateString, format = longMonth): string {
	const utcTime = moment.utc(dateString);
	const localTime = utcTime.local();
	return localTime.format(format);
}

export function formatShortMonthWithoutTimeDatetime(dateString): string {
	return formatDate(dateString, shortMonthWithoutTime);
}

export function formatShortMonthDatetime(dateString): string {
	return formatDate(dateString, shortMonth);
}

export function sortByDate<T>(items: T[], dateField: string): T[] {
	return _.sortBy(items, (dateObj) => {
		return new Date(dateObj[dateField]);
	});
}

export function isoDateToMilliseconds(isoDate): number {
	return new Date(isoDate).getTime();
}

export const daysToMillis = (days: number): number => {
	return days * 24 * 60 * 60 * 1000;
};

export const millisToDays = (days: number): number => {
	return days / (24 * 60 * 60 * 1000);
};

export const getDateFromNow = (days: number): Date => new Date(Date.now() - daysToMillis(days));

export default {
	formatDate
};
