import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LocationDescriptorObject } from 'history';

import {
	EVINCED_SEVERITIES,
	EvSpinner,
	FormatHelper,
	useSkipLinks
} from '@evinced-private/ui-common';

import { useConfiguration } from 'src/providers/configurationProvider/ConfigurationProvider';
import { SiteScannerUiToggles } from 'src/services/LocalTogglesService';

import { ComponentsCharts } from '../../../../components/charts/ComponentsChartsV3';
import { EvincedAdvantageChartV3 } from '../../../../components/charts/EvincedAdvantage';
import { IssuesCharts } from '../../../../components/charts/IssuesChartsV3';
import { PagesCharts } from '../../../../components/charts/PagesChartsV3';
import EvLinkLocal from '../../../../components/common/ev-link-local/EvLinkLocal';
import { ALL_CONTENT_VIEW_ID } from '../../../../components/common/ev-property-views/consts/PropertyViews';
import NoPagesMatchErrorPage from '../../../../components/common/ev-property-views/NoPagesMatchErrorPage';
import SummaryPane from '../../../../components/common/summary-pane/SummaryPane';
import SummaryPaneV2 from '../../../../components/common/summary-pane/SummaryPaneV2';
import { ChartsSectionV3 } from '../../../../components/data-displays/overview-charts/charts-section/ChartsSectionV3';
import GeneralError from '../../../../components/general-error/GeneralError';
import HandsIcon from '../../../../components/icons/HandsIcon.svg';
import ApiErrorHelper from '../../../../helpers/ApiErrorHelper';
import RoutesHelper from '../../../../helpers/RoutesHelper';
import ScanAndPropertyResultsHelper from '../../../../helpers/ScanAndPropertyResultsHelper';
import ScoreHelper from '../../../../helpers/ScoreHelper';
import { IIssuesOverviewResponse } from '../../../../interfaces/IssuesOverviewResponse';
import { ScanScore } from '../../../../interfaces/ScanScore';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import Logger from '../../../../services/Logger';
import scanService, { ScanOverviewResponse } from '../../../../services/scan/ScanService';
import scansService from '../../../../services/scan/ScansService';
import { ComponentOverviewResponse, PagesOverviewResponse } from '../../../../types/OverviewTypes';
import TabsViews from '../../../../types/TabsViews';
import LoadErrorMsgLayout from '../../../property/LoadErrorMsgLayout';

import TopPagesByIssueCountTableV2 from './tables/top-pages/TopPagesByIssueCountTableV2';

import './ScanOverviewView.scss';

interface IScanOverviewViewProps {
	scanId: string;
	propertyId: string;
	viewId?: string;
	renderActionButtons?: () => JSX.Element;
}

const ScanOverviewViewV3: FC<IScanOverviewViewProps> = ({
	scanId,
	propertyId,
	viewId,
	renderActionButtons
}: IScanOverviewViewProps) => {
	const history = useHistory();
	const [pagesOverview, setPagesOverview] = useState<PagesOverviewResponse>(null);
	const [issuesOverview, setIssuesOverview] = useState<IIssuesOverviewResponse>(null);
	const [componentsOverview, setComponentsOverview] = useState<ComponentOverviewResponse>(null);
	const [scanScore, setScanScore] = useState<number>(null);
	const [error, setError] = useState<string>(null);
	const [isLoading, setIsLoading] = useState(true);

	const { getToggle } = useConfiguration();
	const enableEvincedAdvantageDiagram = getToggle(
		SiteScannerUiToggles.ENABLE_EVINCED_ADVANTAGE_DIAGRAM
	);

	const totalCriticalIssues = useMemo(
		() => ScanAndPropertyResultsHelper.getIssuesCount(issuesOverview, EVINCED_SEVERITIES.CRITICAL),
		[issuesOverview]
	);

	const hideCharts = useMemo(() => viewId && viewId !== ALL_CONTENT_VIEW_ID, [viewId]);

	const noIssues = useMemo(
		() => pagesOverview?.overview?.pagesWithIssues === 0 && issuesOverview?.issuesCount === 0,
		[pagesOverview, issuesOverview]
	);

	useEffect(() => {
		const loadScanOverview = async (): Promise<void> => {
			setIsLoading(true);
			try {
				const scanOverview: ScanOverviewResponse = await scanService.getScanOverview(
					scanId,
					viewId
				);
				const scanScore: ScanScore[] = await scansService.getScansScore([scanId], viewId);
				setIssuesOverview(scanOverview.issues);
				setPagesOverview(scanOverview.pages);
				setComponentsOverview(scanOverview.components);
				setScanScore(ScoreHelper.roundScore(scanScore[0]?.scoreResult?.score) || 0);
			} catch (error) {
				Logger.error('Error occured while getting scan data', error);
				setError(
					`Error occured while getting scan data. ${ApiErrorHelper.getErrorMessage(error) ?? ''}`
				);
			} finally {
				if (viewId !== undefined) {
					setIsLoading(false);
				}
			}
		};
		loadScanOverview();
	}, [scanId, viewId]);

	// updating skip links data when page is loaded
	useSkipLinks(!isLoading);

	const renderLinkToAllComponents = useCallback((): JSX.Element => {
		const url = RoutesHelper.getScanResultComponentsBreakdownPath(propertyId, scanId);
		return (
			<EvLinkLocal url={url} linkText="View all components" ariaLabel="view more components" />
		);
	}, [propertyId, scanId]);

	if (error) {
		return (
			<div className="scan-overview-view">
				<GeneralError title="Error loading scan">{error}</GeneralError>
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className="scan-overview-view">
				<EvSpinner />
			</div>
		);
	}

	const getSummaryPaneDataV2 = (): SummaryItem[] => {
		const summary: SummaryItem[] = [
			{
				value: `${scanScore}`,
				label: 'Score / 100',
				isDashedBorder: true
			},
			{
				value: FormatHelper.formatNumber(issuesOverview.issuesCount),
				label: 'Number of issues'
			},
			{
				value: FormatHelper.formatNumber(totalCriticalIssues),
				label: 'Critical issues'
			},
			{
				value: FormatHelper.formatNumber(pagesOverview.overview.pagesWithIssues),
				label: 'Pages with issues'
			}
		];

		return summary;
	};

	const redirectToPage = (stateKey, stateValue, tabView: TabsViews): void => {
		history.push({
			pathname: RoutesHelper.getScanDetailsView(propertyId, scanId, tabView),
			state: {
				[stateKey]: stateValue,
				isTemporary: true
			}
		} as LocationDescriptorObject);
	};

	const redirectToPagesPage = (stateKey, stateValue): void => {
		redirectToPage(stateKey, stateValue, TabsViews.PAGES);
	};

	const redirectToAllIssuesPage = (stateKey, stateValue): void => {
		redirectToPage(stateKey, stateValue, TabsViews.BREAKDOWN);
	};

	const redirectToComponentsPage = (componentId): void => {
		history.push({
			pathname: RoutesHelper.getComponentDrilldownPath(propertyId, scanId, componentId),
			state: {
				isTemporary: true
			}
		} as LocationDescriptorObject);
	};

	if (noIssues) {
		return (
			<LoadErrorMsgLayout
				mainTitle="No issues"
				secondaryMsg={[
					"This test ran successfully and didn't contain issues.",
					'Keep up the good work.'
				]}
				icon={HandsIcon}
			/>
		);
	}

	return (
		<div className="scan-overview-view">
			<SummaryPane
				items={getSummaryPaneDataV2()}
				sectionLabel="Scan summary"
				className="overview-summary-pane"
				renderActionButtons={renderActionButtons}
			/>

			{hideCharts ? (
				<>
					<PagesCharts
						viewId={viewId}
						pagesSeverities={pagesOverview.severities}
						pagesScanned={pagesOverview.overview.scanned}
						onBarItemClick={redirectToPagesPage}
					/>
					<NoPagesMatchErrorPage />
				</>
			) : (
				<>
					<IssuesCharts
						severitiesData={issuesOverview.severities}
						totalIssues={issuesOverview.issuesCount}
						onItemClick={redirectToAllIssuesPage}
					/>
					<SummaryPaneV2
						className="grouping-of-issues-into-components"
						title="Grouping of issues into components"
						items={[
							{
								label: 'Total number of components',
								value: FormatHelper.formatNumber(componentsOverview.componentsCount)
							},
							{
								label: 'Components with critical issues',
								value: FormatHelper.formatNumber(componentsOverview.criticalComponentsCount)
							}
						]}
						sectionLabel="Grouping of issues into components"
						renderActionButtons={renderLinkToAllComponents}
					/>
					<ComponentsCharts
						scanId={scanId}
						propertyId={propertyId}
						componentsCountHash={componentsOverview}
						totalCriticalIssues={totalCriticalIssues}
						onComponentTableLinkClick={redirectToComponentsPage}
					/>
					<PagesCharts
						pagesSeverities={pagesOverview.severities}
						pagesScanned={pagesOverview.overview.scanned}
						onBarItemClick={redirectToPagesPage}
					/>
					<ChartsSectionV3
						sectionTextBlockTitle="Top 5 worst performing pages"
						skiplinkId="pages-table"
					>
						<div
							className={`overview-tables-section ${enableEvincedAdvantageDiagram ? 'gap-10' : 'chart-wrapper'}`}
						>
							<TopPagesByIssueCountTableV2 scanId={scanId} propertyId={propertyId} />

							{enableEvincedAdvantageDiagram ? (
								<EvincedAdvantageChartV3
									severitiesData={issuesOverview.severities}
									totalIssues={totalCriticalIssues}
								/>
							) : null}
						</div>
					</ChartsSectionV3>
				</>
			)}
		</div>
	);
};

export { ScanOverviewViewV3 };
