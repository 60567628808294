import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import classNames from 'classnames';

import { EvMainTitle } from '@evinced-private/ui-common';

import EvPropertyViews from '../components/common/ev-property-views/EvPropertyViews';
import PropertyViewLoadErrorPage from '../components/common/ev-property-views/PropertyViewLoadErrorPage';
import { ScanSummaryTags } from '../components/scan-summary-tags/ScanSummaryTags';
import ScanTabsNavigation from '../components/scan-tabs-navigation/ScanTabsNavigation';
import { formatDate } from '../helpers/DateFormatHelper';
import { IScan } from '../interfaces/Scan';
import { useConfiguration } from '../providers/configurationProvider/ConfigurationProvider';
import { IPropertyViewContext, useProperyViewContext } from '../providers/PropertyViewProvider';
import { SiteScannerUiToggles } from '../services/LocalTogglesService';
import Logger from '../services/Logger';
import scansService from '../services/scan/ScansService';
import TabsViews from '../types/TabsViews';

import './style.scss';

interface IScanAnalysisPageProps {
	view: TabsViews;
	actionElement?: JSX.Element;
}

const withTabsNavigation = (WrappedComponent): FC<IScanAnalysisPageProps> => {
	return (props: IScanAnalysisPageProps): JSX.Element => {
		const { propertyId, scanId } = useParams<{ [key: string]: string }>();
		const [scan, setScan] = useState<IScan>(null);
		const properyViewContext: IPropertyViewContext = useProperyViewContext();
		const { getToggle } = useConfiguration();
		const viewLoadErr: boolean = useMemo(
			() => properyViewContext?.loadViewErr,
			[properyViewContext]
		);
		const scanName = `Scan ${formatDate(scan?.createdTime)}`;
		const headerClassNames: string = classNames('header-container', 'with-tabs-navigation-header');

		const fetchScan = useCallback(async (): Promise<void> => {
			try {
				const scan: IScan = await scansService.getScanMetadata(scanId);
				setScan(scan);
			} catch (error) {
				Logger.error(
					`Error scan/scans details for scan id ${scanId}, property ${propertyId}`,
					error
				);
				setScan(null);
			}
		}, [scanId, propertyId]);

		useEffect(() => {
			if (scanId) {
				fetchScan();
			} else {
				setScan(null);
			}
		}, [scanId, fetchScan]);

		const tabNavigationComponent: JSX.Element = <ScanTabsNavigation />;

		if (viewLoadErr) {
			return <PropertyViewLoadErrorPage propertyId={propertyId} />;
		}
		return (
			<div className="tab-navigation-wrapper">
				<div className={headerClassNames}>
					{scanId && scan && <EvMainTitle className="page-heading">{scanName}</EvMainTitle>}
					{getToggle(SiteScannerUiToggles.ENABLE_SCAN_OVERVIEW_V3) ? (
						<ScanSummaryTags propertyId={propertyId} scanId={scanId} />
					) : null}
					{tabNavigationComponent}
					<div>
						<div className={classNames('action-element', 'with-views')}>{props.actionElement}</div>
						<div className="views">
							<EvPropertyViews propertyId={propertyId} />
						</div>
					</div>
				</div>
				<WrappedComponent {...props} />
			</div>
		);
	};
};

export default withTabsNavigation;
