import React, { FunctionComponent } from 'react';

import classNames from 'classnames';

import { EvSection } from '@evinced-private/ui-common';

import IdFormatterHelper from '../../../../helpers/IdFormatterHelper';

import './ChartsSection.scss';

interface IChartsSectionProps {
	sectionTextBlockTitle: string;
	/**
	 * skiplinkId to be passed to the section, required for skiplinks
	 */
	skiplinkId: string;
	className?: string;
	children: JSX.Element | JSX.Element[];
}

const ChartsSectionV3: FunctionComponent<IChartsSectionProps> = ({
	sectionTextBlockTitle,
	children,
	className,
	skiplinkId
}: IChartsSectionProps) => {
	const sectionTitleId = IdFormatterHelper.formatTextToId(sectionTextBlockTitle);
	const classes: string = classNames('charts-section', { [className]: !!className });

	return (
		<EvSection className={classes} ariaLabelledby={sectionTitleId} skipLinkId={skiplinkId}>
			{children}
		</EvSection>
	);
};

export { ChartsSectionV3 };
