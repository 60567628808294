import { BUTTON_TYPES, DropdownOptionType } from '@evinced-private/ui-common';

import { ExportType } from 'src/interfaces/ExportType';

type DownloadIssueDropdownOption = {
	id: string;
	type: DropdownOptionType;
	buttonProps: {
		title: string;
		type: BUTTON_TYPES;
		onClick: () => void;
		children: string;
		dataAutomationId: string;
	};
};

/**
 * @param onClickCb - callback function to handle the download option click
 * @returns DownloadIssueDropdownOption[] - array of download options (ex: CSV, Excel)
 */
export const downloadIssuesDropdownOptions = (
	onClickCb: (type: ExportType) => void
): DownloadIssueDropdownOption[] => [
	{
		id: 'export-to-csv',
		type: DropdownOptionType.BUTTON,
		buttonProps: {
			title: 'CSV',
			type: BUTTON_TYPES.ICON,
			children: 'CSV',
			dataAutomationId: 'export-to-csv-button',
			onClick: () => onClickCb(ExportType.CSV)
		}
	},
	{
		id: 'export-to-excel',
		type: DropdownOptionType.BUTTON,
		buttonProps: {
			title: 'Excel',
			type: BUTTON_TYPES.ICON,
			children: 'Excel',
			dataAutomationId: 'export-to-excel-button',
			onClick: () => onClickCb(ExportType.XLSX)
		}
	}
];

export const csvSuccessAnnouncement = 'Export started. You will get it by email in a few minutes.';
export const excelSuccessAnnouncement =
	"Export started. You will get it by email as soon as it's ready.";
export const defaultErrorAnnouncement = 'Server error, please try again.';
export const errorAnnouncement = 'Unable to export. Filter the issue count below 200k.';
