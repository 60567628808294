import { UsageEventsService } from '@evinced-private/ui-common';
import { PRODUCT_TYPES } from '@evinced-private/ui-common/services';

import ServerApi from '../../api/ServerApi';
import { USAGE_METRICS } from '../../consts/usageMetrics';
import EnvironmentHelper from '../../helpers/EnvironmentHelper';

let isInitialized = false;

export const isUsageAnalyticsServiceInitialized = (): boolean => isInitialized;

export const initUsageAnalyticsService = (userId: string, tenantId: string): void => {
	UsageEventsService.init({
		userId,
		tenantId,
		productName: PRODUCT_TYPES.SCANNER,
		productVersion: UI_VERSION,
		isProductionEnv: EnvironmentHelper.isProduction(),
		/* TODO: sendEventToggle temporary. change real/parsed BE value when
		ready or remove this param and parse it in the service */
		sendEventToggle: true,
		serverApi: ServerApi
	});
	isInitialized = true;
};

export const sendUserActivityEvent = (): void => {
	UsageEventsService.sendEvent(USAGE_METRICS.USER_ACTIVITY, {
		activity: USAGE_METRICS.USER_ACTIVITY
	});
};
