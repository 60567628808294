/* eslint-disable no-unsafe-optional-chaining */
import React, { FC, useCallback, useMemo, useState } from 'react';

import { EvMultiLineChart, SeriesLineOptions, VIEW_MODES } from '@evinced-private/ui-common';

import ErrorLoadPageIcon from '../../../../components/icons/ErrorLoadPageIcon.svg';
import GraphIcon from '../../../../components/icons/GraphIcon.svg';
import NoPagesMatchIcon from '../../../../components/icons/NoPagesMatchIcon.svg';
import { ERROR_TYPE } from '../../../../consts/errors';
import { calculateXAxisTickPositions } from '../../../../helpers/charts/MultiLineChartXAxisHelper';
import DashboardHelper from '../../../../helpers/dashboard/DashboardHelper';
import { formatShortMonthDatetime, getDateFromNow } from '../../../../helpers/DateFormatHelper';
import { useConfiguration } from '../../../../providers/configurationProvider/ConfigurationProvider';
import { SiteScannerUiToggles } from '../../../../services/LocalTogglesService';
import LoadErrorMsgLayout from '../../../property/LoadErrorMsgLayout';
import { PERFORMANCE_OVER_TIME_CHART_TYPE } from '../../DashboardConsts';
import { TScansWithStatus, TView } from '../../DashboardTypes';

import './PerformanceOverTimeChart.scss';

const errorsData = {
	failed: {
		title: 'Failed to load graph',
		description: 'Please try refreshing the page or selecting different views.',
		icon: ErrorLoadPageIcon
	},
	noData: {
		title: 'No scans found',
		description: 'Please try refreshing the page or selecting different views.',
		icon: NoPagesMatchIcon
	},
	notSelected: {
		title: 'See your progress',
		description: 'Please select a view to see the graph.',
		icon: GraphIcon
	},
	noDataInTimeFrame: {
		title: 'No scans found',
		description: 'Please refine chart filters.',
		icon: NoPagesMatchIcon
	}
};

interface IPerformanceOverTimeChart {
	scansMap?: Map<string, TScansWithStatus>;
	allViewsMap?: Map<string, TView>;
	chartType: { key: PERFORMANCE_OVER_TIME_CHART_TYPE; label: string };
	yAxisLabels: { [key: string]: string };
	isNotSelected?: boolean;
	performanceOverTimeViewMode: VIEW_MODES;
	setPerformanceOverTimeViewMode: React.Dispatch<React.SetStateAction<VIEW_MODES>>;
	timeFrame?: number;
}

const getErrorMessage = (numberOfFailedViews: number): string => {
	if (numberOfFailedViews && numberOfFailedViews < 3) {
		const isOnly = numberOfFailedViews === 1;
		return `${isOnly ? 'One' : 'Two'} of your requested views ${
			isOnly ? 'is' : 'are'
		} not available. Please select different ${isOnly ? 'view' : 'views'}.`;
	}
	return null;
};

const PerformanceOverTimeChart: FC<IPerformanceOverTimeChart> = ({
	scansMap,
	allViewsMap,
	chartType,
	yAxisLabels,
	isNotSelected,
	performanceOverTimeViewMode,
	setPerformanceOverTimeViewMode,
	timeFrame = 90
}) => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const { getToggle } = useConfiguration();

	const multiChartColumnsPattern = useMemo(
		() => [
			{
				name: 'Properties / Views',
				field: 'propertyView',
				multiline: true
			},
			{
				name: 'Scans',
				field: 'x',
				formatter: (value: string) => formatShortMonthDatetime(value)
			},
			{
				name: chartType.label,
				field: 'y'
			},
			{
				name: 'Pages',
				field: 'pages'
			}
		],
		[chartType]
	);

	const yAxisLabel = useMemo(() => {
		return yAxisLabels[chartType.key];
	}, [yAxisLabels, chartType]);

	const scansWithValues = useMemo(() => {
		let numberOfFailedViews = 0;

		const newScansMap = new Map();
		[...(scansMap?.entries() || [])].forEach(([key, value]) => {
			if (!value.failed) {
				newScansMap.set(key, value.scans);
			} else {
				numberOfFailedViews++;
			}
		});
		setErrorMessage(getErrorMessage(numberOfFailedViews));
		return newScansMap;
	}, [scansMap]);

	const multiChartData: SeriesLineOptions[] = useMemo(() => {
		if (scansWithValues?.size) {
			return DashboardHelper.getScoreMultiLineChartData(
				scansWithValues,
				allViewsMap,
				chartType.key,
				yAxisLabel,
				timeFrame,
				!getToggle(SiteScannerUiToggles.ENABLE_PERFORMANCE_OVER_TIME_CHART_V2)
			);
		}
		return [];
	}, [allViewsMap, chartType, yAxisLabel, scansWithValues, timeFrame, getToggle]);

	const errorType: ERROR_TYPE | null = useMemo(() => {
		if (isNotSelected) {
			return ERROR_TYPE.NOT_SELECTED;
		}
		if (scansMap) {
			const areAllViewsFailed = [...scansMap?.values()].every((value) => value.failed);
			if (areAllViewsFailed) {
				return ERROR_TYPE.FAILED;
			}
			const isThereNoScans = [...scansMap?.values()].every((value) => value.scans?.length === 0);
			if (isThereNoScans) {
				return ERROR_TYPE.NO_DATA;
			}
			const timeFrameCutOff = getDateFromNow(timeFrame);
			const isOutOfTimeFrame = [...scansMap?.values()].every((value) =>
				value.scans?.every((scan) => {
					const scanDate = new Date(scan.createdTime);
					return scanDate < timeFrameCutOff;
				})
			);
			if (isOutOfTimeFrame) {
				return ERROR_TYPE.NO_DATA_IN_TIME_FRAME;
			}
		}
		return null;
	}, [scansMap, isNotSelected, timeFrame]);

	const renderErrorState = useCallback(() => {
		const errorData = errorsData[errorType];
		return (
			<div className="performance-chart-error-wrapper">
				<LoadErrorMsgLayout
					mainTitle={errorData.title}
					secondaryMsg={errorData.description}
					icon={errorData.icon}
				/>
			</div>
		);
	}, [errorType]);

	const tickPositions = useMemo(
		() => calculateXAxisTickPositions(multiChartData),
		[multiChartData]
	);

	return (
		<div className="performance-over-time-chart-wrapper">
			<EvMultiLineChart
				className="performance-over-time-multi-chart"
				title=""
				description=""
				height={getToggle(SiteScannerUiToggles.ENABLE_PERFORMANCE_OVER_TIME_CHART_V2) ? 496 : 320}
				yAxisLabel={yAxisLabel}
				series={multiChartData}
				viewMode={performanceOverTimeViewMode}
				onViewChange={setPerformanceOverTimeViewMode}
				tableProps={{
					dataType: 'Score',
					columnsPattern: multiChartColumnsPattern
				}}
				isError={!!errorType}
				renderErrorState={renderErrorState}
				xAxis={
					getToggle(SiteScannerUiToggles.ENABLE_PERFORMANCE_OVER_TIME_CHART_V2)
						? {
								type: 'datetime',
								tickPositions: tickPositions.length && tickPositions
							}
						: {
								type: 'datetime',
								tickPixelInterval: 120
							}
				}
				errorMessage={errorMessage}
				tooltipOptions={{
					formatter(): string {
						const { pages } = this.point as unknown as { pages: number };
						return `<div style="font-size: 14px;">
								<span>&nbsp;${yAxisLabel}: ${this.y}</span><br/><br/>
								<span>Scan date: ${formatShortMonthDatetime(this.key)}</span><br/><br/>
								<span>Pages: ${pages}</span><br/><br/>
							</div>`;
					}
				}}
				legendYOffset={
					getToggle(SiteScannerUiToggles.ENABLE_PERFORMANCE_OVER_TIME_CHART_V2) ? 154 : undefined
				}
			/>
		</div>
	);
};

export default PerformanceOverTimeChart;
