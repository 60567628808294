import ServerApi from '../../api/ServerApi';
import API_URL_PATHS from '../../consts/ApiUrlPaths';
import { ProductType, TenantProduct } from '../../interfaces/Tenant';
import Logger from '../Logger';

const { CENTRAL_CONFIG_PATH } = API_URL_PATHS;
export type TConfiguration = Record<string, Record<string, unknown>>;
const getScannerProductID = (products: TenantProduct[]): string => {
	if (!products) {
		return null;
	}
	return products.find((product: TenantProduct) => product.type === ProductType.SCANNER)?.id;
};

export const getCentralConfig = async (products: TenantProduct[]): Promise<TConfiguration> => {
	const scannerProductID = getScannerProductID(products);
	if (scannerProductID) {
		try {
			const configurationResult: { SITE_SCANNER_UI: TConfiguration } = await ServerApi.apiCall(
				'GET',
				`${CENTRAL_CONFIG_PATH}?productId=${scannerProductID}&subPath=SITE_SCANNER_UI`,
				{ isConfigUrl: true }
			);

			return configurationResult?.SITE_SCANNER_UI;
		} catch (error) {
			Logger.error('Error fetching central configuration', error);
			return null;
		}
	}
	return null;
};
