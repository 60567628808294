import React, { useEffect, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import { FormatHelper } from '@evinced-private/ui-common';

import RoutesHelper from '../../helpers/RoutesHelper';
import { useConfiguration } from '../../providers/configurationProvider/ConfigurationProvider';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import { SiteScannerUiToggles } from '../../services/LocalTogglesService';
import Logger from '../../services/Logger';
import scanService, { ScanOverviewResponse } from '../../services/scan/ScanService';
import { PagesOverviewResponse } from '../../types/OverviewTypes';
import EvLinkLocal from '../common/ev-link-local/EvLinkLocal';

import './ScanSummaryTags.scss';

interface ScanSummaryTagsProps {
	propertyId: string;
	scanId: string;
}

const ScanSummaryTags = ({ propertyId, scanId }: ScanSummaryTagsProps): JSX.Element => {
	const [pagesOverview, setPagesOverview] = useState<PagesOverviewResponse>(null);
	const { getToggle } = useConfiguration();
	const properyViewContext: IPropertyViewContext = useProperyViewContext();

	const viewId = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);

	useEffect(() => {
		const loadScanOverview = async (): Promise<void> => {
			try {
				const scanOverview: ScanOverviewResponse = await scanService.getScanOverview(
					scanId,
					viewId
				);
				setPagesOverview(scanOverview.pages);
			} catch (error) {
				setPagesOverview(null);
				Logger.error('Error occured while getting scan data', error);
			}
		};
		loadScanOverview();
	}, [scanId, viewId]);

	return pagesOverview && getToggle(SiteScannerUiToggles.ENABLE_SCAN_OVERVIEW_V3) ? (
		<div className="header-summary-pane" aria-label="scanned pages summary">
			<p>Scanned pages:</p>
			<EvLinkLocal
				url={RoutesHelper.getScanResultUrlsStatusPath(propertyId, scanId)}
				linkText={`${FormatHelper.formatNumber(pagesOverview.overview.scanned)} scanned`}
				className="tag-link tag-info"
			/>
			<EvLinkLocal
				url={RoutesHelper.getScanResultUrlsStatusPath(propertyId, scanId)}
				linkText={`${FormatHelper.formatNumber(pagesOverview.overview.filteredOut)} ${pluralize(
					'duplicate',
					pagesOverview.overview.filteredOut
				)}`}
				className="tag-link tag-info"
			/>
			<EvLinkLocal
				url={RoutesHelper.getScanResultUrlsStatusPath(propertyId, scanId)}
				linkText={`${FormatHelper.formatNumber(pagesOverview.overview.failed)} failed`}
				className="tag-link tag-info"
			/>
		</div>
	) : null;
};

export { ScanSummaryTags };
