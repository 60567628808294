import { asyncHandler } from '../helpers/AsyncHelper';
import logger from '../services/Logger';
import {
	IAttachmentAdded,
	ICreatedIssue,
	IIssueMeta,
	IIssueMetaResponse,
	IProjectsMetaResponse,
	IProjectValue,
	ITokenData,
	JiraCloudData
} from '../types/JiraTypes';

import localStorageApi from './LocalStorageApi';

const scopes =
	'offline_access%20read:jira-work%20read:jira-user%20write:jira-work%20read:issue-meta:jira%20read:field-configuration:jira%20read:issue-type:jira%20read:project:jira%20read:project.property:jira%20read:user:jira%20read:application-role:jira%20read:avatar:jira%20read:group:jira%20read:issue-type-hierarchy:jira%20read:project-category:jira%20read:project-version:jira%20read:project.component:jira%20read:label:jira%20read:attachment:jira%20write:attachment:jira';
const jiraAccessibleResourcesUrl = process.env.JIRA_ACCESSIBLE_RESOURSES_URL;
const jiraAuthURL = process.env.JIRA_AUTH_TOKEN_URL;
const jiraApiURL = process.env.JIRA_API_URL;
const clientID = process.env.JIRA_APP_CLIENT_ID;
const clientSecret = process.env.JIRA_APP_CLIENT_SECRET;
const redirectURL = process.env.JIRA_REDIRECT_URL;

type InnerCallApiParams = {
	url: string;
	method?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload?: any;
	contentType?: string;
	withToken?: boolean;
	retries?: number;
};

type JqlAutocompleteSuggestion = {
	value: string;
	displayName: string;
};

type JqlAutocompleteSuggestionsResponse = {
	results: JqlAutocompleteSuggestion[];
};

type AutoCompleteOption = { label?: string; displayName?: string };

type AutoCompleteOptions = AutoCompleteOption[];
export default class JiraApi {
	private static cloudId: string;

	private static host: string;

	public static setJiraData(cloudId: string, host: string): void {
		this.cloudId = cloudId;
		this.host = host;
	}

	public static getJiraAuthURL(): string {
		const userID = localStorageApi.getUserFromLocalStorage();
		// used for 'state' param (required for security) - a value that is associated with the user
		// that is directed to the authorization URL
		const state = userID?.userId;
		return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientID}&scope=${scopes}&redirect_uri=${redirectURL}&state=${state}&response_type=code&prompt=consent`;
	}

	private static innerCallApi<T>({
		url,
		method = 'GET',
		payload,
		contentType = 'application/json',
		withToken = true,
		retries = 1
	}: InnerCallApiParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
	Promise<T> {
		const requestHeaders: HeadersInit = new Headers();
		const fetchOptions: RequestInit = {
			mode: 'cors',
			method
		};
		// In case of multipart/form-data - we should not set content type, it will set
		// automatically because of payload
		if (contentType !== 'multipart/form-data') {
			requestHeaders.set('Content-Type', contentType);
		}
		if (withToken) {
			const jiraToken = JSON.parse(localStorageApi.getJiraAccessToken());
			if (jiraToken?.access_token) {
				requestHeaders.set('Authorization', `Bearer ${jiraToken.access_token}`);
			} else {
				return Promise.reject(new Error('Unauthorized'));
			}
		}
		requestHeaders.set('accept', 'application/json');
		if (payload) {
			console.log('payload', payload);
			if (contentType === 'application/json') {
				fetchOptions.body = JSON.stringify(payload);
			} else {
				fetchOptions.body = payload;
				requestHeaders.set('x-atlassian-token', 'no-check');
			}
		}
		fetchOptions.headers = requestHeaders;
		logger.debug('request started: ', url);
		return this.fetchWithAuthorizedRetries(url, fetchOptions, retries);
	}

	private static async fetchWithAuthorizedRetries(
		url,
		options: RequestInit,
		retries
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> {
		const response = await fetch(url, options);
		if (response.ok) {
			logger.debug('request complete successfully: ', url);
			const data = await response.json();
			logger.debug('Response for ', url, 'is:', data);
			return data;
		}
		if (retries > 0) {
			if (response.status === 401) {
				await this.refreshJiraToken();
				const jiraToken = JSON.parse(localStorageApi.getJiraAccessToken());
				options.headers = {
					...options.headers,
					Authorization: `Bearer ${jiraToken.access_token}`
				};
				return this.fetchWithAuthorizedRetries(url, options, retries - 1);
			}
			logger.error(
				'Error in fetchWithAuthorizedRetries',
				url,
				response?.status,
				response?.status?.toString(),
				response?.statusText
			);
		}
		return Promise.reject(new Error(response.status.toString()));
	}

	public static async getAndSaveJiraToken(code): Promise<void> {
		const jiraToken = localStorageApi.getJiraAccessToken();
		if (jiraToken) {
			window.close();
			return;
		}
		const payload = {
			grant_type: 'authorization_code',
			client_id: clientID,
			client_secret: clientSecret,
			code,
			redirect_uri: redirectURL
		};

		const data = await this.innerCallApi<ITokenData>({
			url: jiraAuthURL,
			method: 'POST',
			payload,
			withToken: false
		});
		if (data.access_token) {
			localStorageApi.setJiraAccessToken(JSON.stringify(data));
			window.close();
		}
	}

	public static async refreshJiraToken(): Promise<void> {
		const jiraToken = JSON.parse(localStorageApi.getJiraAccessToken());
		const payload = {
			grant_type: 'refresh_token',
			client_id: clientID,
			client_secret: clientSecret,
			refresh_token: jiraToken.refresh_token
		};

		const [data, error] = await asyncHandler<ITokenData>(
			this.innerCallApi({
				url: jiraAuthURL,
				method: 'POST',
				payload,
				withToken: false,
				retries: 0
			}),
			`Error refreshing Jira token ${jiraAuthURL} payload: ${JSON.stringify(payload)}`
		);
		if (error) {
			localStorageApi.removeJiraAccessToken();
			Promise.reject(new Error('Unauthorized'));
		}
		if (data?.access_token) {
			localStorageApi.setJiraAccessToken(JSON.stringify(data));
		}
	}

	public static getJiraCloudId(): Promise<JiraCloudData> {
		return this.innerCallApi({ url: jiraAccessibleResourcesUrl });
	}

	public static async getProjectIssueTypes(projectId: string): Promise<IProjectValue> {
		return this.innerCallApi<IProjectValue>({
			url: `${jiraApiURL}/${this.cloudId}/rest/api/2/project/${projectId}?expand=issueTypes`
		});
	}

	public static getProjectsMeta(): Promise<IProjectsMetaResponse> {
		return this.innerCallApi<IProjectsMetaResponse>({
			url: `${jiraApiURL}/${this.cloudId}/rest/api/2/project`
		});
	}

	public static getIssueMeta(projectId, issueTypeId): Promise<IIssueMeta> {
		return this.innerCallApi<IIssueMetaResponse>({
			url: `${jiraApiURL}/${this.cloudId}/rest/api/2/issue/createmeta?projectIds=${projectId}&issuetypeIds=${issueTypeId}&expand=projects.issuetypes.fields`
		}).then((json) => {
			return json?.projects?.[0]?.issuetypes?.[0] || null;
		});
	}

	private static objectToQueryParams(params: Record<string, string>): string {
		return Object.keys(params)
			.filter((key) => params[key] !== undefined)
			.map((key) => `${key}=${params[key]}`)
			.join('&');
	}

	private static async callJqlAutoCompleteSuggestions(
		params: Record<string, string>
	): Promise<AutoCompleteOptions> {
		const response = await this.innerCallApi<JqlAutocompleteSuggestionsResponse>({
			url: `${jiraApiURL}/${this.cloudId}/rest/api/2/jql/autocompletedata/suggestions?${this.objectToQueryParams(params)}`
		});
		const suggestions = response?.results?.map(({ value }) => ({ label: value }));
		return suggestions;
	}

	private static async getLabelsAutocompleteOptions(
		originUrl: string
	): Promise<AutoCompleteOptions> {
		const url = new URL(originUrl);
		const fieldValue = url.searchParams.get('query');
		const params = { fieldName: 'labels', fieldValue };
		return this.callJqlAutoCompleteSuggestions(params);
	}

	private static removeBoldHtmlTags(displayName: string): string {
		return displayName.replace(/<\/?b>/g, '');
	}

	private static async getReporterAutocompleteOptions(
		originUrl: string
	): Promise<AutoCompleteOptions> {
		const url = new URL(originUrl);
		// based on the form:
		// rest/api/2/user/recommend?context=Reporter&issueKey=a"
		// migrating to jql request as described here:
		// https://developer.atlassian.com/cloud/jira/platform/rest/v2/api-group-jql/#api-rest-api-2-jql-autocompletedata-suggestions-get
		const fieldValue = url.searchParams.get('issueKey');
		const params = { fieldName: 'reporter', fieldValue };

		const response = await this.innerCallApi<JqlAutocompleteSuggestionsResponse>({
			url: `${jiraApiURL}/${this.cloudId}/rest/api/2/jql/autocompletedata/suggestions?${this.objectToQueryParams(params)}`
		});
		return response.results.map(({ value, displayName }) => {
			return {
				value,
				label: this.removeBoldHtmlTags(displayName)
			};
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static async getAutocompleteValues(autocompleteUrl: string): Promise<AutoCompleteOptions> {
		if (autocompleteUrl.includes('labels/')) {
			return this.getLabelsAutocompleteOptions(autocompleteUrl);
		}

		if (autocompleteUrl.includes('api/2/user/recommend?context=Reporter')) {
			return this.getReporterAutocompleteOptions(autocompleteUrl);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.innerCallApi<any>({ url: autocompleteUrl }).then((json) => {
			return json.suggestions || json;
		});
	}

	public static createIssue(issue): Promise<ICreatedIssue> {
		return this.innerCallApi<ICreatedIssue>({
			url: `${jiraApiURL}/${this.cloudId}/rest/api/2/issue`,
			method: 'POST',
			payload: issue
		}).then((json) => json);
	}

	public static async addAttachmentToIssue(issueId, attachmentUrl): Promise<IAttachmentAdded> {
		return fetch(attachmentUrl)
			.then((res) => {
				if (!res.ok) {
					throw new Error('Network response for attachment was not OK');
				}
				return res.blob();
			})
			.then((image) => {
				const formData = new FormData();
				formData.append('name', 'screenshot.jpeg');
				formData.append('file', image);
				formData.append('filename', 'screenshot.jpeg');
				return this.innerCallApi<IAttachmentAdded>({
					url: `${jiraApiURL}/${this.cloudId}/rest/api/2/issue/${issueId}/attachments`,
					method: 'POST',
					payload: formData,
					contentType: 'multipart/form-data'
				}).then((json) => json);
			});
	}

	public static openJiraTicket(ticketKey): void {
		window.open(`${this.host}/browse/${ticketKey}`, '_blank');
	}
}
