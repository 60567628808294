import React, { FC } from 'react';

import { BUTTON_TYPES, EvButton, EvSelect, OptionType } from '@evinced-private/ui-common';

interface IPerformanceOverTimeSelectGroup {
	selectedViews: OptionType[];
	onMultiViewSelect: (values: OptionType[]) => void;
	viewsOptions: OptionType[];
	isApplyButtonDisabled: boolean;
	onApplyView: () => void;
}

export const PerformanceOverTimeSelectGroup: FC<IPerformanceOverTimeSelectGroup> = ({
	selectedViews,
	onMultiViewSelect,
	viewsOptions,
	isApplyButtonDisabled,
	onApplyView
}) => {
	return (
		<div className="select-group">
			<EvSelect
				value={selectedViews}
				onChange={onMultiViewSelect}
				options={viewsOptions}
				placeholder="Select views"
				isMulti
			/>
			<EvButton disabled={isApplyButtonDisabled} type={BUTTON_TYPES.ACTION} onClick={onApplyView}>
				Apply
			</EvButton>
		</div>
	);
};
