import React, { FC } from 'react';

import { EvSpinner } from '@evinced-private/ui-common';

import chartsHelper from '../../helpers/ChartsHelper';
import { ComponentOverviewResponse } from '../../types/OverviewTypes';
import ChartWrapper from '../data-displays/overview-charts/chart-wrapper/ChartWrapper';
import { ChartsSectionV3 } from '../data-displays/overview-charts/charts-section/ChartsSectionV3';

import { CriticalComponentsRaceBarV3 } from './bars/CriticalComponentsRaceBarV3';

interface IComponentChartsProps {
	totalCriticalIssues: number;
	propertyId: string;
	componentsCountHash: ComponentOverviewResponse;
	scanId?: string;
	onComponentTableLinkClick?: (componentId: string) => void;
}

const ComponentsCharts: FC<IComponentChartsProps> = ({
	totalCriticalIssues,
	propertyId,
	componentsCountHash,
	scanId,
	onComponentTableLinkClick
}) => {
	if (!componentsCountHash) {
		return <EvSpinner />;
	}

	const renderCriticalComponentsRaceBar = (): JSX.Element => {
		const componentsWithCritical = chartsHelper.getComponentsWithCriticalV2(componentsCountHash);

		return (
			<ChartWrapper title="Breakdown by components">
				<CriticalComponentsRaceBarV3
					componentsWithCritical={componentsWithCritical}
					totalCriticalIssues={totalCriticalIssues}
					propertyId={propertyId}
					scanId={scanId}
					onTableLinkClick={onComponentTableLinkClick}
					className="breakdown-by-components-race-bar"
				/>
			</ChartWrapper>
		);
	};

	return (
		<ChartsSectionV3
			sectionTextBlockTitle="Grouping of issues into components"
			className="avoid-break"
			skiplinkId="components-charts"
		>
			<div className="charts-row">
				{componentsCountHash ? <>{renderCriticalComponentsRaceBar()}</> : <EvSpinner />}
			</div>
		</ChartsSectionV3>
	);
};

export { ComponentsCharts };
