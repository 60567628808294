import React, { FunctionComponent, useMemo } from 'react';

import { EvSpinner, SORT_ORDER } from '@evinced-private/ui-common';

import SeverityService from '../../services/severities/SeverityService';
import { SeverityData } from '../../types/OverviewTypes';
import { ALL_CONTENT_VIEW_ID } from '../common/ev-property-views/consts/PropertyViews';
import ChartWrapper from '../data-displays/overview-charts/chart-wrapper/ChartWrapper';
import { ChartsSectionV3 } from '../data-displays/overview-charts/charts-section/ChartsSectionV3';

import { PagesByIssueTypeBar } from './bars/PagesByIssueTypeBarV3';
import PagesBySeverityBarV2 from './bars/PagesBySeverityBarV2';

import './PagesChartsV2.scss';

const ISSUE_SEVERITY_CHART_TITLE = 'Pages impacted by issue severity chart';

interface IPagesChartProps {
	pagesSeverities: SeverityData[];
	pagesScanned: number;
	viewId?: string;
	onBarItemClick?: (stateKey: string, stateValue: string) => void;
}

const PagesCharts: FunctionComponent<IPagesChartProps> = ({
	pagesScanned,
	pagesSeverities,
	viewId,
	onBarItemClick
}: IPagesChartProps) => {
	const orderedPagesSeverities = useMemo(() => {
		return pagesSeverities?.sort((a, b) =>
			SeverityService.sortBySeverity(a.severity, b.severity, SORT_ORDER.DESC)
		);
	}, [pagesSeverities]);

	const noPagesMatch: boolean = pagesSeverities?.length === 0;

	// We hide the charts and not the ChartsSection in order to show the ChartsSection summary pane
	const hidePagesCharts = viewId && viewId !== ALL_CONTENT_VIEW_ID && noPagesMatch;

	return (
		<ChartsSectionV3
			sectionTextBlockTitle="Distribution of issues across pages"
			skiplinkId="pages-charts"
			className="pages-charts"
		>
			{hidePagesCharts ? null : (
				<div className="charts-row">
					{pagesSeverities ? (
						<>
							<ChartWrapper title={ISSUE_SEVERITY_CHART_TITLE}>
								<PagesBySeverityBarV2
									pagesSeverities={orderedPagesSeverities}
									pagesScanned={pagesScanned}
									onBarItemClick={onBarItemClick}
								/>
							</ChartWrapper>
							<ChartWrapper className="avoid-break">
								<PagesByIssueTypeBar
									pagesSeverities={orderedPagesSeverities}
									pagesScanned={pagesScanned}
									onBarItemClick={onBarItemClick}
								/>
							</ChartWrapper>
						</>
					) : (
						<EvSpinner />
					)}
				</div>
			)}
		</ChartsSectionV3>
	);
};

export { PagesCharts };
