import React, { FC } from 'react';

import { EvSection } from '@evinced-private/ui-common';

import { IProperty } from '../../../interfaces/Property';
import { IPropertyError } from '../../../interfaces/PropertyError';
import { SetPropertyType } from '../../../pages/property-settings/PropertySettingsPageTypes';
import { useConfiguration } from '../../../providers/configurationProvider/ConfigurationProvider';
import { SiteScannerUiToggles } from '../../../services/LocalTogglesService';
import SECTION_TYPES from '../../../types/SettingsSectionTypes';
import FileTypeSettings from '../sections/file-type-settings/FileTypeSettings';
import GroupingSettings from '../sections/grouping-settings/GroupingSettings';
import LoginSettings from '../sections/login-settings/LoginSettings';
import SchedulingSettings from '../sections/scheduling-settins/SchedulingSettings';
import ScopeSettings from '../sections/scope-settings/ScopeSettings';
import SeedsList from '../sections/seeds-list/SeedsList';

import './PropertySettingsContent.scss';

interface IPropertySettingsContentProps {
	originalProperty: IProperty;
	property: IProperty;
	propertyErrors: IPropertyError[];
	removePropertyError: (sectionId: SECTION_TYPES) => void;
	isListMode: boolean;
	setProperty: SetPropertyType;
}

const PropertySettingsContent: FC<IPropertySettingsContentProps> = ({
	originalProperty,
	property,
	propertyErrors,
	removePropertyError,
	isListMode,
	setProperty
}: IPropertySettingsContentProps) => {
	const { getToggle } = useConfiguration();
	const renderLoginSettings = (): JSX.Element => {
		return (
			<LoginSettings
				originalProperty={originalProperty}
				property={property}
				setProperty={setProperty}
				propertyError={propertyErrors.find((error) => error.sectionId === SECTION_TYPES.LOGIN)}
			/>
		);
	};

	const renderFileTypeSettings = (): JSX.Element => {
		return getToggle(SiteScannerUiToggles.ENABLE_CRAWL_FILE_TYPES_OPTION) ? (
			<FileTypeSettings property={property} setProperty={setProperty} />
		) : null;
	};

	const renderSchedulingSettings = (): JSX.Element => {
		return (
			<SchedulingSettings
				property={property}
				setProperty={setProperty}
				propertyError={propertyErrors.find((error) => error.sectionId === SECTION_TYPES.SCHEDULING)}
			/>
		);
	};

	// list mode (upload)
	if (isListMode) {
		return (
			<EvSection
				className="property-settings-content"
				ariaLabel="Property list settings"
				skipLinkId="list-settings"
			>
				<SeedsList
					title="Urls list"
					property={property}
					setProperty={setProperty}
					propertyError={propertyErrors.find((error) => error.sectionId === SECTION_TYPES.SEEDS)}
					removePropertyError={removePropertyError}
					isListMode={isListMode}
				/>
				{renderLoginSettings()}
				{renderSchedulingSettings()}
			</EvSection>
		);
	}

	// crawl mode (discovery)
	return (
		<EvSection
			className="property-settings-content"
			ariaLabel="Property crawl settings"
			skipLinkId="crawl-settings"
		>
			<SeedsList
				property={property}
				setProperty={setProperty}
				propertyError={propertyErrors.find((error) => error.sectionId === SECTION_TYPES.SEEDS)}
				removePropertyError={removePropertyError}
				isListMode={isListMode}
			/>
			<ScopeSettings
				property={property}
				setProperty={setProperty}
				propertyError={propertyErrors.find((error) => error.sectionId === SECTION_TYPES.SCOPE)}
			/>
			{renderFileTypeSettings()}
			<GroupingSettings
				property={property}
				setProperty={setProperty}
				propertyError={propertyErrors.find((error) => error.sectionId === SECTION_TYPES.GROUPING)}
			/>
			{renderLoginSettings()}
			{renderSchedulingSettings()}
		</EvSection>
	);
};

export default PropertySettingsContent;
