import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
	BUTTON_TYPES,
	EvButton,
	EvErrorCard,
	EvIcon,
	EvLink,
	EvSnippetView,
	EvSpinner,
	EvTitle,
	EvToast,
	EvTooltip,
	TITLE_MODES,
	TOAST_STATUS
} from '@evinced-private/ui-common';

import ClipboardHelper from '../../helpers/ClipboardHelper';
import scansService from '../../services/scan/ScansService';
import { ScreenshotService } from '../../services/ScreenshotService';
import { Report } from '../../types/ReportModel';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import CopyIcon from '../icons/CopyIcon.svg';
import NoScreenShotImage from '../icons/NoScreenShotImage.svg';

import './ComponentDetailsPopup.scss';

interface IComponentDetailsPopupProps {
	scanId: string;
	issueId: string;
	componentId: string;
	totalPages: number;
	totalIssues: number;
	onClose?: () => void;
}

const successCopiedMsg = 'Copied to Clipboard';
const failedCopiedMsg = 'Copy Attempt Error';

const ComponentDetailsPopup: FC<IComponentDetailsPopupProps> = ({
	scanId,
	issueId,
	componentId,
	onClose,
	totalPages,
	totalIssues
}: IComponentDetailsPopupProps) => {
	const [issue, setIssue] = useState<Report>(null);
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [image, setImage] = useState<string>(null);
	const [copyState, setCopyState] = useState<TOAST_STATUS>(TOAST_STATUS.SUCCESS);
	const [copyMessage, setCopyMessage] = useState<string>(successCopiedMsg);
	const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);

	const { type, severity } = issue || {};

	const copyToClipboardButton = useRef(null);

	const onToastClose = (): void => {
		setShowAnnouncement(false);
		copyToClipboardButton.current.focus();
	};

	const copyToClipboard = (text): void => {
		const isCopied = ClipboardHelper.copyToClipboard(text);
		setCopyState(isCopied ? TOAST_STATUS.SUCCESS : TOAST_STATUS.FAIL);
		setCopyMessage(isCopied ? successCopiedMsg : failedCopiedMsg);
		setShowAnnouncement(true);
	};

	const renderToast = (): JSX.Element => (
		<EvToast
			announcement={copyMessage}
			status={copyState}
			show={showAnnouncement}
			onToastClose={onToastClose}
		/>
	);

	const resolveImage = useCallback(
		async (issueData: Report): Promise<void> => {
			const boundingBoxes = issueData.elements
				.filter((e) => e.componentId === componentId)
				.map((element) => element.boundingBox);
			try {
				const screenshotImageData = await ScreenshotService.getFullPageScreenshotWithIssues({
					pageScreenshotUrl: issueData.pageScreenshot,
					boundingBoxes
				});
				setImage(screenshotImageData);
			} catch (e) {
				setImage(null);
			}
		},
		[componentId]
	);

	const fetchIssue = useCallback(async (): Promise<void> => {
		try {
			setLoading(true);
			const issueData: Report = await scansService.getScanIssue(scanId, issueId);
			setIssue(issueData);
			await resolveImage(issueData);
		} catch (e) {
			setError(e);
		}
		setLoading(false);
	}, [issueId, resolveImage, scanId]);

	useEffect(() => {
		if (!issue) {
			fetchIssue();
		}
	}, [issue, fetchIssue]);

	const renderImage = (): JSX.Element => {
		if (image) {
			return <img className="screenshot-img" src={image} alt="Issue Screenshot" />;
		}
		return (
			<EvIcon className="image-not-found-icon" icon={NoScreenShotImage} title="not available" />
		);
	};

	const renderPart = (name: string, value: JSX.Element | string): JSX.Element => {
		return (
			<div className="part">
				<EvTitle className="name" titleText={name} headingLevel={3} />
				<div className="value">{value}</div>
			</div>
		);
	};

	const renderBody = (): JSX.Element => {
		if (!issue || isLoading) {
			return (
				<div className="spinner-wrapper">
					<EvSpinner />
				</div>
			);
		}

		const element = issue.elements.find((e) => e.componentId === componentId);
		return (
			<div className="component-details-content">
				{showAnnouncement && renderToast()}
				<div className="section-header">
					<div className="title">
						<EvTitle titleText={`Component ${componentId}`} mode={TITLE_MODES.MAIN} />
					</div>
					<div className="sub-title">
						This component contains {totalIssues} issues over {totalPages} pages
					</div>
				</div>
				<div className="element-details">
					<div className="details-block">
						<div className="block-title">Example Issue</div>
						<div className="component-details">
							{renderPart(
								'URL:',
								<EvLink
									url={issue.elements[0].pageUrl}
									showIcon={true}
									openInNewTab={true}
									customTooltip={{ dataTip: issue.elements[0].pageUrl, dataFor: 'popup-tooltip' }}
								/>
							)}
							{renderPart(
								'Selector:',
								<div className="selector-with-copy">
									{element.selector}
									<EvButton
										ref={copyToClipboardButton}
										type={BUTTON_TYPES.ICON}
										onClick={() => copyToClipboard(element.selector)}
										title="copy selector"
									>
										<EvIcon icon={CopyIcon} />
									</EvButton>
								</div>
							)}
							{renderPart('Issue Type:', type.name)}
							{renderPart('Severity:', severity.name)}
							<div className="snippet" aria-label="DOM Snippet">
								<EvSnippetView title="" snippet={element.domSnippet} codeType="html" />
							</div>
						</div>
					</div>
				</div>
				{image && <div className="screenshot-picture">{renderImage()}</div>}
				<EvTooltip place="top" id="popup-tooltip" />
			</div>
		);
	};

	return (
		<div>
			<SiteScannerPopup
				isOpen
				title={type?.name}
				isSrOnly
				className="component-details-popup"
				isBgTransparent
				isControlled
				onClose={onClose}
				popupWidth="unset"
			>
				{renderBody()}
			</SiteScannerPopup>
			<SiteScannerPopup
				isOpen={!!error}
				title="Error"
				isSrOnly
				className="error-popup"
				isBgTransparent
				isCentered
				isControlled
				onClose={() => {
					setError(false);
					onClose();
				}}
				popupWidth="unset"
			>
				<EvErrorCard title="Error" contactUsMessage={error?.error?.message} />
			</SiteScannerPopup>
		</div>
	);
};

export default ComponentDetailsPopup;
